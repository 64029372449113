<template>
  <div class="wrap">
    <div class="btn">
      <div></div>
      <div>
        <el-button :disabled="imgList.length == 0" size="medium" :loading="downLoading" @click="downloadPhoto">{{ downLoading ? '下载中...' : '下载' }}</el-button>
        <span v-if="isSelect" style="margin-left: 20px">
          <el-checkbox v-model="checkAll" style="margin-right: 10px" @change="handleCheckAllChange">全选</el-checkbox>
          <el-button size="medium" type="primary" @click="del('批量')">批量删除</el-button>
          <el-button class="cancel" size="medium" type="primary" @click="isSelect = false">取消管理</el-button>
        </span>
        <el-button v-else size="medium" type="primary" @click="isSelect = true">管理影像</el-button>
      </div>
    </div>
    <div>
      <el-scrollbar v-if="imgList.length > 0 && imgList.find((e) => e.imgs.length > 0)" ref="elScroll" v-loading="loading" class="scrollBox" style="height: 500px">
        <div v-for="(item, i) in imgList" :key="i">
          <div v-if="item.imgs.length > 0" class="name">
            <span class="line"></span>
            {{ item.proName }}
          </div>
          <el-checkbox-group v-model="checkedList" @change="handleCheckedCitiesChange">
            <div v-for="(n, v) in item.imgs" :key="v" class="imgs">
              <el-image
                v-if="n.fileType == 1"
                lazy
                class="img"
                fit="fill"
                :src="showUrl(n.url, `x-oss-process=image/resize,m_fixed,h_${imageSize},w_${imageSize}`)"
                :preview-src-list="isSelect ? [] : getImgList(item, v)"
              >
                <span slot="error" class="el-image-error">
                  <el-image class="img" fit="fill" :src="n.url" :preview-src-list="isSelect ? [] : getImgList(item, v)" lazy>
                    <span slot="error" class="el-image-error">暂无图片</span>
                  </el-image>
                </span>
              </el-image>
              <div v-else class="video">
                <el-image class="img" :src="n.coverUrl" fit="fill">
                  <span slot="error">
                    <span slot="error" class="el-image-error">加载失败</span>
                  </span>
                </el-image>
                <div class="play" @click="toPlayVideo(n)">
                  <div class="icon"></div>
                </div>
              </div>
              <el-checkbox v-if="isSelect" :label="n.id"></el-checkbox>
              <el-button v-if="isSelect" size="mini" class="del" type="danger" icon="el-icon-delete" circle @click="del(n.id)"></el-button>
            </div>
          </el-checkbox-group>
        </div>
      </el-scrollbar>
      <el-empty v-else :image-size="200"></el-empty>

      <!-- <Pages :currentPage="form.pageNumber" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" /> -->
    </div>
    <UploadPhotoPopups v-if="isUploadPhoto" type="1" popups-title="上传照片" :show-state="isUploadPhoto" :current-data="currentData" @hidePopups="isUploadPhoto = false" />
    <my-video :visible="videoVisible" :url="videoUrl" @cancel="videoVisible = false" />
  </div>
</template>

<script>
import UploadPhotoPopups from '@/views/orderMgt/orderList/components/uploadPhoto'
import { showUrl } from '@/utils/yaImg'
import { findFileListByOrderNo, findShowBaseProductTypesByOrderNo, deleteFileByIds } from '@/api/order'
import myVideo from '@/components/video/video.vue'

export default {
  components: {
    UploadPhotoPopups,
    myVideo,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    currentData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      videoUrl: '',
      videoVisible: false,
      checkAll: false,
      checkedList: [],
      imgList: [],
      isUploadPhoto: false,
      isSelect: false,
      form: {
        pageSize: 10,
        pageNumber: 1,
        data: {},
      },
      total: 0,
      imageSize: 200,
      previews: [],
      loading: false,
      downLoading: false,
    }
  },
  watch: {
    isSelect(val) {
      if (!val) {
        this.checkedList = []
        this.checkAll = false
      }
    },
  },
  async mounted() {
    await this.findShowBaseProductTypesByOrderNo()
  },
  methods: {
    showUrl,
    getImgList(data, index) {
      const list = data.previews || []
      return list.slice(index).concat(list.slice(0, index))
    },
    async findShowBaseProductTypesByOrderNo() {
      this.loading = true
      try {
        await findShowBaseProductTypesByOrderNo({
          data: {
            orderNo: this.currentData.orderNo,
            businessId: this.currentData.serviceId,
            bizLineId: this.currentData.bizLineId,
          },
        }).then(async (res) => {
          const data = res.data
          await Promise.all(
            data.map(
              async (e) =>
                await this.getPhotoList(e.baseProductType).then((res) => {
                  e.imgs = res.data
                  e.previews = res.data.map((e) => e.url)
                }),
            ),
          )
          this.imgList = data
        })
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },

    upPhoto() {
      this.isUploadPhoto = true
    },
    getPhotoList(e) {
      return findFileListByOrderNo({
        data: {
          orderNo: this.currentData.orderNo,
          baseProductType: e,
          businessId: this.currentData.serviceId,
          bizLineId: this.currentData.bizLineId,
        },
      })
    },
    toPlayVideo(file) {
      this.videoVisible = true
      this.videoUrl = file.url
    },
    del(id) {
      let tip = '是否确定删除该照片'
      let ids = [id]

      if (id === '批量' && this.checkedList.length === 0) {
        this.$message({
          message: '请至少选择一张照片',
          type: 'warning',
        })
        return
      }
      if (id === '批量') {
        tip = `是否确定删除所选的${this.checkedList.length}照片`
        ids = this.checkedList
      }
      this.$confirm(tip, '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        deleteFileByIds({
          data: ids,
        }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.isSelect = false
          this.findShowBaseProductTypesByOrderNo()
        })
      })
    },
    async downloadPhoto() {
      const data = []
      console.log(this.imgList)
      this.imgList.forEach((e) => {
        e.imgs.forEach((v, i) => {
          data.push({
            url: v.url.split('?')[0],
            name: e.proName + '/' + (i + 1),
            suffix: v.url.substring(v.url.lastIndexOf('.')),
          })
        })
      })
      this.downLoading = true
      await this.$downloadPhoto(data)
      this.downLoading = false
    },
    handleCheckAllChange(val) {
      this.checkedList = []
      if (val) {
        this.imgList.forEach((e) => {
          e.imgs.forEach((v) => this.checkedList.push(v.id))
        })
      }
    },
    handleCheckedCitiesChange(value) {
      const checkedCount = value.length
      this.checkAll = checkedCount === this.imgList.length
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageNumber = 1
      this.getPhotoList()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.getPhotoList()
    },
  },
}
</script>

<style lang="less" scoped>
@import '../../../../styles/imgsList.less';
.name {
  display: flex;
  align-items: center;
  padding: 20px 0;
}
.line {
  display: inline-block;
  width: 4px;
  height: 20px;
  background-color: #0079fe;
  margin-right: 8px;
}
</style>
