<template>
  <div class="record">
    <template v-if="inventoryData.length">
      <div v-for="(item, index) in inventoryData" :key="index">
        <div class="header">
          <div>操作时间：{{ item.createTime }}</div>
          <div>操作人：{{ item.platformName + '/' + item.createUserName }}</div>
        </div>
        <el-table
          :data="item.serviceInventoryList"
          :span-method="arraySpanMethod"
          border
          class="table"
        >
          <el-table-column prop="" label="基础产品基本信息" min-width="350px">
            <template slot-scope="{ row }">
              <div class="base-info">
                <span class="info-title">{{ row.productName }}</span>
                <span v-if="row.mountingProduct" class="button" @click="lookMounting(row)"
                  >查看装裱</span
                >
              </div>
              <div class="base-info">
                <span class="info-text"
                  >产品类型：<span>{{ row.baseProductTypeName }}</span></span
                >
                <span v-if="row.baseProductType === 9" class="info-text"
                  >相册页数：<span>{{ row.designNum }}</span></span
                >
                <span class="info-text"
                  >产品规格：<span>{{ row.specification }}</span></span
                >
                <span class="info-text"
                  >计费单位：<span>{{ row.priceUnit }}</span></span
                >
                <span v-if="row.baseProductType === 9" class="info-text"
                  >照片张数：<span>{{ row.photos }}</span></span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="orderNo" label="来源订单编号" min-width="150px"> </el-table-column>
          <el-table-column prop="orderTypeName" label="订单类型" min-width="80px">
          </el-table-column>
          <el-table-column prop="number" label="数量" min-width="60px"> </el-table-column>
          <el-table-column prop="sumPayAmount" label="实付总价(元)" min-width="100px">
          </el-table-column>
          <el-table-column label="操作" min-width="170px">
            <template slot-scope="{ row }">
              <el-button
                v-if="row.inventoryButtonDto && row.inventoryButtonDto.removeBtn"
                type="primary"
                size="mini"
                @click="remove(row)"
                >移除</el-button
              >
              <div v-if="row.isRemove">
                <div class="status">已移除</div>
                <div>操作时间：{{ row.removeLogResDto.createTime }}</div>
                <div>
                  操作人：{{ row.removeLogResDto.platformName }} /
                  {{ row.removeLogResDto.createUserName }}
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
    <div v-else class="record-box">
      <el-empty description="暂无数据"></el-empty>
    </div>
    <mount-dialog
      :show.sync="mountShow"
      :form="row.mountingProduct"
      :is-price="false"
      :is-number="false"
    />
  </div>
</template>

<script>
import { getServiceInventoryRecord, removeInventory } from '@/api/order.js'
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inventoryData: [],
      serviceId: '',
      page: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
      },
      mountShow: false,
      row: {},
    }
  },
  watch: {
    id: {
      handler(val) {
        this.serviceId = val
        this.getServiceInventoryRecord()
      },
      immediate: true,
    },
  },
  methods: {
    remove(row) {
      this.$confirm(
        '确定要移除库存产品吗？移除后，本服务订单将不使用移除产品，产品将返回库存',
        '移除确认',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          confirmButtonType: 'text',
          type: 'warning',
        },
      )
        .then(() => this.removeInventory(row))
        .catch(() => {})
    },
    removeInventory({ serviceId, removeBatch, productId, productName, baseProductType }) {
      const params = {
        data: {
          serviceId,
          removeBatch,
          productId,
          productName,
          baseProductType,
        },
      }
      removeInventory(params).then(({ success }) => {
        if (success) {
          this.$message.success('移除成功')
          this.getServiceInventoryRecord()
        }
      })
    },
    lookMounting(row) {
      this.mountShow = true
      this.row = row
    },
    arraySpanMethod({ row, columnIndex }) {
      if ([0, 5].includes(columnIndex)) {
        if (row.merge) {
          return {
            rowspan: row.merge,
            colspan: 1,
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          }
        }
      }
    },
    // 处理合并数据
    handleMergeData(data) {
      if (!data.serviceInventoryList) return
      const serviceInventoryList = data.serviceInventoryList
      let merge = 0
      let removeBatch = serviceInventoryList[0].removeBatch
      let index = 0
      for (let i = 0; i < serviceInventoryList.length; i++) {
        if (removeBatch === serviceInventoryList[i].removeBatch) {
          merge++
        } else {
          serviceInventoryList[index].merge = merge
          removeBatch = serviceInventoryList[i].removeBatch
          merge = 1
          index = i
        }
        if (i === serviceInventoryList.length - 1) {
          serviceInventoryList[index].merge = merge + 1
        }
      }

      return { ...data, serviceInventoryList }
    },
    getServiceInventoryRecord() {
      getServiceInventoryRecord({
        data: {
          serviceId: this.id,
          operateType: 1,
        },
        ...this.page,
      }).then((res) => {
        this.inventoryData = res.data.content.map((v) => this.handleMergeData(v))
        this.total = +res.data.total
      })
    },
  },
}
</script>

<style lang="less" scoped>
.record {
  padding: 20px;
  box-sizing: border-box;
}
.header {
  display: flex;
  background-color: #2861e2;
  border-radius: 4px 4px 0 0;
  height: 30px;
  line-height: 30px;
  padding: 0 20px;
  color: #fff;
  font-size: 14px;
  & > div:first-child {
    margin-right: 16px;
  }
}

.table {
  margin-bottom: 16px;
}

.base-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;

  &:first-child {
    justify-content: space-between;
  }

  .info-title {
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    color: #000;
  }

  .info-text {
    color: #9a9d9e;
    white-space: nowrap;
    width: 140px;
    text-overflow: ellipsis;
    overflow: hidden;
    & > span {
      color: #000;
    }
  }

  .button {
    color: #2861e2;
    cursor: pointer;
  }
}

.status {
  color: #f59a23;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background: transparent;
}
.record-box {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
