<template>
  <div v-loading="photosLoading" class="photos wrap">
    <div class="btn">
      <el-form ref="form" label-width="100px">
        <el-form-item label="筛选类型：">
          <el-select v-model="baseProductType" placeholder="请选择类型" style="width: 150px" @change="typeChange">
            <el-option v-for="(item, i) in typeList" :key="i" :label="item.name" :value="item.val"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div>
        <el-button :disabled="photos.length == 0" :loading="downLoading" size="medium" @click="downloadPhoto">{{ downLoading ? '下载中...' : '下载' }}</el-button>
        <span v-if="isSelect" style="margin-left: 20px">
          <el-checkbox v-model="checkAll" style="margin-right: 10px" @change="handleCheckAllChange">全选</el-checkbox>
          <el-button size="medium" type="primary" @click="sync('批量')">批量同步</el-button>
          <el-button size="medium" type="primary" @click="del('批量')">批量删除</el-button>
          <el-button class="cancel" size="medium" type="primary" @click="isSelect = false">取消管理</el-button>
        </span>
        <el-button v-else size="medium" type="primary" @click="isSelect = true">管理影像</el-button>
      </div>
    </div>

    <el-scrollbar ref="elScroll" class="scrollBox" style="height: 500px">
      <div v-if="photos.length > 0">
        <el-checkbox-group v-model="checkedList" @change="handleCheckedCitiesChange">
          <div v-for="(item, i) in photos" :key="item.id" class="imgs">
            <el-image
              v-if="item.baseProductType != 15 && item.baseProductType != 14"
              class="img"
              fit="fill"
              :src="showUrl(item.url, `x-oss-process=image/resize,m_fixed,h_${imageSize},w_${imageSize}`)"
              :preview-src-list="isSelect ? [] : getImgList(i)"
              lazy
            >
              <span slot="error" class="el-image-error">
                <el-image class="img" fit="fill" :src="item.url" :preview-src-list="isSelect ? [] : getImgList(i)" lazy>
                  <span slot="error" class="el-image-error">加载失败</span>
                </el-image>
              </span>
            </el-image>

            <div v-else class="video">
              <el-image class="img" :src="item.coverUrl" fit="cover">
                <span slot="error">
                  <span slot="error" class="el-image-error">加载失败</span>
                </span>
              </el-image>
              <div class="play" @click="toPlayVideo(item)">
                <div class="icon"></div>
              </div>
            </div>
            <div v-if="item.userPhotosSelect" class="user-photos-select">已选</div>
            <el-button v-if="isSelect" size="mini" class="sync" @click="sync(item.id)">同步至影像馆</el-button>
            <el-checkbox v-if="isSelect" :label="item.id"></el-checkbox>
            <el-button v-if="isSelect" size="mini" class="del" type="danger" icon="el-icon-delete" circle @click="del(item.id)"></el-button>
          </div>
        </el-checkbox-group>
      </div>
      <el-empty v-else :image-size="200"></el-empty>
    </el-scrollbar>
    <!-- <Pages :currentPage="form.pageNumber" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" /> -->
    <my-video :visible="videoVisible" :url="videoUrl" @cancel="videoVisible = false" />
    <el-dialog class="sync_dialog" title="同步确认" :visible.sync="dialogVisible" width="400px" :before-close="syncCancel">
      <span class="dialog_content">
        <i style="color: #e6a23c; font-size: 24px" class="el-icon-warning"></i>
        &ensp;
        {{ tip }}
      </span>
      <span slot="footer" class="dialog-footer">
        <el-checkbox v-model="isChecked" size="mini" :true-label="1" :false-label="0">提醒客户已上传</el-checkbox>
        <span>
          <el-button size="small" @click="syncCancel">取 消</el-button>
          <el-button size="small" type="primary" :loading="syncLoading" @click="syncOk">确 定</el-button>
        </span>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { showUrl } from '@/utils/yaImg'
import { getDetailPhotos, deleteBathUserPhoto, syncPhoto } from '@/api/order'
import myVideo from '@/components/video/video.vue'
export default {
  components: {
    myVideo,
  },
  props: {
    currentData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      videoVisible: false,
      videoUrl: '',
      baseProductType: 1,
      photos: [],
      connectPhone: '',
      previews: [],
      imageSize: 200,
      photosLoading: false,
      form: { data: { baseProductType: 1 }, pageNumber: 1, pageSize: 9999 },
      total: 0,
      typeList: [
        { name: '原片', val: 1 },
        { name: '初修', val: 5 },
        { name: '精修', val: 6 },
        { name: '设计版面', val: 7 },
        { name: '原视频', val: 15 },
        { name: '剪辑视频', val: 14 },
      ],
      checkAll: false,
      checkedList: [],
      isUploadPhoto: false,
      isSelect: false,
      downLoading: false,
      dialogVisible: false,
      syncLoading: false,
      tip: '',
      ids: [],
      isChecked: 0,
    }
  },
  watch: {
    isSelect(val) {
      if (!val) {
        this.checkedList = []
        this.checkAll = false
      }
    },
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    showUrl,
    typeChange(e) {
      this.form.pageNumber = 1
      this.form.data.baseProductType = e
      this.getImgData()
    },
    async getImgData() {
      this.$nextTick(() => {
        this.$refs.elScroll.$refs.wrap.scrollTop = 0
      })
      this.form.data.bizLineId = this.currentData.bizLineId
      await getDetailPhotos(this.form).then((res) => {
        this.photos = res.data.content
        this.total = Number(res.data.total)
        this.previews = this.photos.map((item) => {
          return item.url
        })
      })
    },
    del(id) {
      let tip = '是否确定删除该照片？'
      let ids = [id]
      if (id === '批量' && this.checkedList.length === 0) {
        this.$message({
          message: '请至少选择一张照片',
          type: 'warning',
        })
        return
      }
      if (id === '批量') {
        tip = `是否确定删除所选的${this.checkedList.length}照片？`
        ids = this.checkedList
      }
      this.$confirm(tip, '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        deleteBathUserPhoto({
          data: {
            ids: ids,
            bizLineId: this.currentData.bizLineId,
          },
        }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.isSelect = false
          this.getInfo()
        })
      })
    },
    syncCancel() {
      this.dialogVisible = false
      this.isChecked = 0
    },

    sync(id) {
      this.tip = '确定要同步至影像馆吗？'
      this.ids = [id]
      if (id === '批量' && this.checkedList.length === 0) {
        this.$message({
          message: '请至少选择一张照片',
          type: 'warning',
        })
        return
      }
      if (id === '批量') {
        this.tip = `是否确定同步所选的${this.checkedList.length}照片至影像馆？`
        this.ids = this.checkedList
      }
      this.dialogVisible = true

      //
    },
    syncOk() {
      this.syncLoading = true
      syncPhoto({
        data: {
          serviceId: this.currentData.serviceId,
          ids: this.ids,
          isRemind: this.isChecked,
          bizLineId: this.currentData.bizLineId,
        },
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '同步成功!',
          })
          this.isSelect = false
          this.syncLoading = false
          this.syncCancel()
          this.getInfo()
        })
        .catch(() => {
          this.syncLoading = false
        })
    },
    async downloadPhoto() {
      const data = this.photos.map((e, i) => {
        return {
          url: e.url.split('?')[0],
          name: i + 1,
          suffix: e.url.substring(e.url.lastIndexOf('.')),
        }
      })
      this.downLoading = true
      await this.$downloadPhoto(data)
      this.downLoading = false
    },
    handleCheckAllChange(val) {
      this.checkedList = val ? this.photos.map((e) => e.id) : []
    },
    handleCheckedCitiesChange(value) {
      const checkedCount = value.length
      this.checkAll = checkedCount === this.photos.length
    },
    async getInfo() {
      try {
        this.photosLoading = true
        this.form.data.serviceId = this.currentData.serviceId
        this.form.pageNumber = 1
        await this.getImgData()

        this.photosLoading = false
      } catch (error) {
        this.photosLoading = false
      }
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageNumber = 1
      this.getImgData()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.getImgData()
    },
    getImgList(index) {
      return this.previews.slice(index).concat(this.previews.slice(0, index))
    },
    toPlayVideo(file) {
      this.videoVisible = true
      this.videoUrl = file.url
    },
  },
}
</script>

<style lang="less" scoped>
@import '../../../../styles/imgsList.less';
.sync {
  position: absolute;
  bottom: 0;
  left: 0;
}
.sync_dialog {
  ::v-deep.el-dialog__header {
    background-color: #fff;
    border: none;
    font-weight: normal;
    padding-top: 15px;
    padding-bottom: 10px;
    .el-dialog__title {
      font-size: 18px;
    }
  }
  ::v-deep.el-checkbox__inner {
    width: 16px;
    height: 16px;
  }
  ::v-deep.el-checkbox__inner::after {
    height: 8px;
    width: 4px;
    left: 4px;
  }
}
.dialog_content {
  display: flex;
  align-items: center;
}
::v-deep .dialog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-photos-select {
  position: absolute;
  top: 0;
  left: 0;
  padding: 4px 6px;
  border-radius: 10px;
  color: #fff;
  background-color: #409eff;
  font-size: 12px;
}
</style>
