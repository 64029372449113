<template>
  <div class="wrap">
    <div v-loading="loading" class="order-list-container">
      <Table type="detailSubOrder" :table-data="tableData" :loading="loading" @refresh="$emit('refresh')" />
      <pages :current-page="form.pageNumber" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
import Table from '@/views/serviceManagement/components/table'
import { storeGetList } from '@/api/order'

export default {
  components: { Table },
  props: {
    serviceId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: {
        pageNumber: 1,
        pageSize: 10,
        data: {},
      },
      total: 0,
      tableData: [],
      loading: false,
      row: {},
      detailDialogVisible: false,
    }
  },
  computed: {
    getCurrentBgC() {
      return (status) => {
        switch (status) {
          case 2:
            return { backgroundColor: '#f59a2338' }
          case 3:
            return { backgroundColor: '#e0c19a' }
          case 4:
            return { backgroundColor: '#e0c19a' }
          case 8:
            return { backgroundColor: '#e0c19a' }
          default:
            return { backgroundColor: '#f59a2338' }
        }
      }
    },

    calculateAllMoney() {
      return (item) => {
        return (Number(item.payAmount) + Number(item.addAmount) - Number(item.refundAmount)).toFixed(2)
      }
    },
  },
  mounted() {
    this.getTableData()
  },
  methods: {
    getTableData() {
      this.loading = true
      this.form.data.serviceId = this.serviceId
      storeGetList(this.form).then((res) => {
        this.total = Number(res.data.total)
        res.data.content.forEach((e) => {
          if (e.serviceItemScore) {
            e.serviceItemScore = Number(e.serviceItemScore)
          }
        })
        this.tableData = res.data.content

        this.loading = false
      })
    },
    dispatch(row) {
      this.row = { ...row }
      this.dialogVisible = true
    },
    detail(row) {
      this.row = { ...row }
      this.detailDialogVisible = true
      this.$router.push({
        path: '/serviceManagement/subOrderDetail',
        query: { serviceItemId: row.serviceItemId },
      })
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageNumber = 1
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.getTableData()
    },
  },
}
</script>

<style lang="less" scoped></style>
