<template>
  <div v-if="serverProtocol.title" class="server-protocol">
    <div class="server-protocol-title">
      {{ serverProtocol.title }}
    </div>
    <div class="server-protocol-time">更新时间：{{ serverProtocol.updateTime }}</div>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="server-protocol-content" v-html="serverProtocol.content"></div>
  </div>
</template>

<script>
export default {
  props: {
    serverProtocol: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.server-protocol {
  margin: 40px 200px;
  padding: 22px 20px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.2);
  border: 2px solid #e0e0e0;
  text-align: center;
  .server-protocol-title {
    font-size: 22px;
  }
  .server-protocol-time {
    font-size: 16px;
  }
  .server-protocol-content {
    font-size: 16px;
    text-align: justify;
  }
}
</style>
