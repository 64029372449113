<template>
  <span class="order-timer">
    {{ hour ? hourString + ':' + minuteString + ':' + secondString : minuteString + ':' + secondString }}
  </span>
</template>

<script>
export default {
  name: 'Timer',
  props: {
    remainTime: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      hour: '',
      minute: '',
      second: '',
      timer: '',
    }
  },
  computed: {
    hourString() {
      return this.formatNum(this.hour)
    },
    minuteString() {
      return this.formatNum(this.minute)
    },
    secondString() {
      return this.formatNum(this.second)
    },
  },
  watch: {
    remainTime: {
      handler(val, oldName) {
        if (val) {
          this.$nextTick(() => {
            if (this.remainTime > 0) {
              this.hour = Math.floor((this.remainTime / 3600) % 24)
              this.minute = Math.floor((this.remainTime / 60) % 60)
              this.second = Math.floor(this.remainTime % 60)
              this.countDowm()
            }
          })
        }
      },
      deep: true,
      // 代表在wacth里声明了firstName这个方法之后立即先去执行handler方法
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    countDowm() {
      const self = this
      clearInterval(this.timer)
      this.timer = setInterval(function () {
        if (self.hour === 0) {
          if (self.minute !== 0 && self.second === 0) {
            self.second = 59
            self.minute -= 1
          } else if (self.minute === 0 && self.second === 0) {
            self.second = 0
            self.$emit('countDowmEnd', true)
            clearInterval(self.timer)
          } else {
            self.second -= 1
          }
        } else {
          if (self.minute !== 0 && self.second === 0) {
            self.second = 59
            self.minute -= 1
          } else if (self.minute === 0 && self.second === 0) {
            self.hour -= 1
            self.minute = 59
            self.second = 59
          } else {
            self.second -= 1
          }
        }
      }, 1000)
    },
    formatNum(num) {
      return num < 10 ? '0' + num : '' + num
    },
  },
}
</script>
