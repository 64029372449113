<template>
  <div>
    <div v-if="orderEvaluationDetail.environmentScore" class="common-sty server-evaluation">
      <!-- <el-descriptions class="info-cotainer" :column="1">
      <el-descriptions-item label="评价时间">2021-08-20 12:00</el-descriptions-item>
    </el-descriptions> -->

      <div v-if="orderEvaluationDetail.starMessage">
        <div class="evaluation-title">
          <span class="title-mark"></span>
          <span class="title-text">超星评价</span>
        </div>
        <div class="super-start">
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              color: #ccc;
              font-size: 14px;
            "
          >
            满意点
          </div>
          <div style="margin-left: 35px; margin-top: 10px">
            <div
              v-for="(item, index) in orderEvaluationDetail.satisfactions"
              :key="index"
              class="satisfied-point"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="super-start">
          <div
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              color: #ccc;
              font-size: 14px;
            "
          >
            超星留言
          </div>
          <div v-for="(item, key, i) in 1" :key="i" class="score" style="width: 100%">
            <div class="score-content">
              <div v-if="!!orderEvaluationDetail.starMessage" class="text">
                {{ orderEvaluationDetail.starMessage }}
              </div>
              <div v-if="!orderEvaluationDetail.starMessage" class="text" style="color: #999">
                暂无内容
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="evaluation-title">
        <span class="title-mark"></span>
        <span class="title-text">整体环境评分</span>
        <span class="title-text-show"
          ><el-rate
            v-model="orderEvaluationDetail.environmentScore"
            disabled
            allow-half
            :colors="['#E86247', '#E86247', '#E86247', '#E86247', '#E86247']"
          ></el-rate
        ></span>
      </div>
      <!-- <el-descriptions class="score-cotainer" :column="4" :labelStyle="{ width: '104px' }">
        <el-descriptions-item :label="itemP.dictionaryName" v-for="(itemP, p) in surroundings.childs" :key="p">
          <el-rate disabled allow-half :colors="['#E86247', '#E86247', '#E86247', '#E86247', '#E86247']" v-model="itemP.score"></el-rate>
        </el-descriptions-item>
      </el-descriptions> -->
      <div class="evaluation-title">
        <span class="title-mark"></span>
        <span class="title-text">整体服务评分</span>
        <span class="title-text-show"
          ><el-rate
            v-model="orderEvaluationDetail.serviceScore"
            disabled
            allow-half
            :colors="['#E86247', '#E86247', '#E86247', '#E86247', '#E86247']"
          ></el-rate
        ></span>
      </div>
      <el-descriptions class="score-cotainer" :column="4" :label-style="{ width: '104px' }">
        <el-descriptions-item
          v-for="(itemS, s) in serversRate"
          :key="s"
          :label="itemS.scoreItemName"
        >
          <el-rate
            v-model="itemS.score"
            disabled
            allow-half
            :colors="['#E86247', '#E86247', '#E86247', '#E86247', '#E86247']"
          ></el-rate>
        </el-descriptions-item>
      </el-descriptions>
      <div class="evaluation-title">
        <span class="title-mark"></span>
        <span class="title-text">评价</span>
      </div>
      <div class="score">
        <div class="score-content">
          <div v-if="!!orderEvaluationDetail.evaluation" class="text">
            {{ orderEvaluationDetail.evaluation }}
          </div>
          <div v-if="!orderEvaluationDetail.evaluation" class="text" style="color: #999">
            暂无内容
          </div>
          <span
            v-for="(itemj, index) in orderEvaluationDetail.evaluationPictureUrl"
            :key="index"
            class="score-img"
          >
            <el-image
              class="certificate-img"
              :src="itemj"
              :preview-src-list="orderEvaluationDetail.evaluationPictureUrl"
              alt=""
              ><span slot="error" class="el-image-error">暂无图片</span></el-image
            >
          </span>
        </div>
        <div v-if="!!orderEvaluationDetail.evaluationReply" class="reply-text">
          <span class="caption">供应商回复：</span>
          {{ orderEvaluationDetail.evaluationReply }}
        </div>
      </div>
      <div class="submit-btn-group">
        <el-button
          v-if="!orderEvaluationDetail.evaluationReply"
          class="submit-btn"
          type="primary"
          @click="handleReply()"
          >去回复</el-button
        >
        <el-button
          v-if="!!orderEvaluationDetail.evaluationReply"
          class="submit-btn-modify"
          @click="handleModifyReply()"
          >去修改回复</el-button
        >
      </div>
      <div class="evaluation-title">
        <span class="title-mark"></span>
        <span class="title-text">投诉建议</span>
      </div>
      <div class="score">
        <div class="score-content">
          <div v-if="!!orderEvaluationDetail.suggest" class="text">
            {{ orderEvaluationDetail.suggest }}
          </div>
          <div v-if="!orderEvaluationDetail.suggest" class="text" style="color: #999">暂无内容</div>
          <span
            v-for="(item, key, i) in orderEvaluationDetail.suggestPictureUrl"
            :key="i"
            class="score-img"
          >
            <el-image
              class="certificate-img"
              :src="item"
              :preview-src-list="orderEvaluationDetail.suggestPictureUrl"
              alt=""
              ><span slot="error" class="el-image-error">暂无图片</span></el-image
            >
          </span>
        </div>
        <div v-if="!!orderEvaluationDetail.suggestReply" class="reply-text">
          <span class="caption">平台回复：</span>
          {{ orderEvaluationDetail.suggestReply }}
        </div>
      </div>
    </div>
    <el-empty v-else :image-size="200"></el-empty>
    <replySupplyEvaluationList
      v-if="dialogVisible"
      :id="orderEvaluationDetail.serviceId"
      :dialog-visible.sync="dialogVisible"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import replySupplyEvaluationList from '../replySupplyEvaluationList.vue'
export default {
  components: { replySupplyEvaluationList },
  props: {
    orderEvaluationDetail: {
      type: Object,
      default: () => ({}),
    },
    surroundings: {
      type: Object,
      default: () => ({}),
    },
    serversRate: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: false,
    }
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    },
    handleReply() {
      this.dialogVisible = true
    },
    handleModifyReply() {
      this.dialogVisible = true
    },
  },
}
</script>

<style lang="scss" scoped>
.server-evaluation {
  padding: 20px;
  .evaluation-title {
    display: -webkit-flex; /* 新版本语法: Chrome 21+ */
    display: -webkit-box; /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
    display: -moz-box; /* 老版本语法: Firefox (buggy) */
    display: -ms-flexbox; /* 混合版本语法: IE 10 */
    display: flex;
    align-items: center;
    color: #000000;
    padding: 11px 0;
    border-bottom: 1px solid #ededed;
    margin: 0 20px;
    .title-mark {
      width: 3px;
      height: 20px;
      margin: 0 20px 0 -20px;
      background-color: #363f47;
      border-radius: 0px 3px 3px 0px;
    }
    .title-text {
      margin-right: 20px;
    }
  }
  .score-cotainer {
    padding: 32px 24px 39px 24px;
  }
  .score {
    font-size: 14px;
    background: #f7f7f7;
    margin: 20px;
    .score-content {
      border-bottom: 1px solid #ededed;
      padding: 20px;
      .text {
        padding: 20px 0 20px 0;
      }
    }
    .score-img {
      margin-right: 16px;
    }
    .reply-text {
      padding: 20px;
      .caption {
        color: #9a9d9e;
      }
    }
    .certificate-img {
      width: 100px;
      height: 100px;
    }
  }
  .reply-button-group {
    margin: 20px;
  }
  .submit-btn-group {
    margin: 0 20px 40px 20px;
    .submit-btn-modify {
      margin-left: 0;
    }
  }
}

.super-start {
  display: flex;
  padding-top: 20px;
  // padding-bottom: 20px;
  margin-left: 20px;
  & > span {
    color: #ccc;
  }
}

.satisfied-point {
  display: inline;
  padding: 5px 10px;
  margin: 0;
  margin-right: 10px;
  margin-top: 6px;
  background-color: #fae0e3;
  float: left;
  margin-bottom: 12px;
  border-radius: 2px;
  // color: red;
  font-size: 12px;
}
</style>
