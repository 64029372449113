<template>
  <div class="server-record">
    <el-scrollbar
      v-if="serverReords.length > 0"
      ref="elScroll"
      v-loading="loading"
      class="scrollBox"
      style="height: 550px; width: 920px"
    >
      <el-timeline style="background-color: #f2f2f250; padding: 10px">
        <el-timeline-item
          v-for="(n, index) in serverReords"
          :key="index"
          color="#0079fe"
          :style="{ width: n.status != 23 ? '650px' : '900px' }"
          :hide-timestamp="true"
        >
          <div style="line-height: 24px">
            {{ n.subject }}
            <span
              v-if="n.serviceState > 4 && n.serviceState < 10"
              :style="{ color: getColor(n.serviceState) }"
              >-{{ n.serviceStateName }}</span
            >
          </div>
          <div class="list">
            <div v-if="!n.subList || n.subList.length == 0" class="subList flex">
              <div style="width: 160px">{{ n.createTime }}</div>
              <span>{{ n.subject }}</span>
            </div>
            <div v-for="(v, i) in n.subList" :key="i" class="subList">
              <div v-if="v.subStatus != 21 && v.subStatus != 3" class="subItem">
                <div style="width: 160px">{{ v.createTime }}</div>
                <div style="flex: 1; padding-left: 140px">
                  <span style="color: #666">{{ v.subject }}</span>

                  <div v-if="v.status != 23">
                    <div
                      v-for="(e, a) in !!v.serviceItems ? JSON.parse(v.serviceItems) : []"
                      :key="a"
                      class="item"
                    >
                      <div style="color: #02a7f0">
                        子订单号：
                        <span v-if="!!e.serviceItemNo" style="cursor: pointer" @click="detail(e)">{{
                          e.serviceItemNo
                        }}</span>
                        <span v-else>--</span>
                      </div>
                      <div>
                        {{ e.serviceUserName || '--' }} |
                        {{ e.phoneNumber || '--' }}
                      </div>
                      <div v-if="!!v.reason" class="reason">{{ v.reason }}</div>
                    </div>
                  </div>
                  <div style="font-size: 14px; color: #7f7f7f">
                    <div
                      v-if="
                        v.serviceState == 3 ||
                        v.serviceState == 2 ||
                        v.status == 25 ||
                        v.status == 6 ||
                        v.status == 5 ||
                        v.status == 22 ||
                        v.subStatus == 19
                      "
                    >
                      <div v-if="v.reason" class="msg">{{ v.reason }}</div>
                      <div v-if="v.createUserName" class="msg">
                        {{ v.createUserName }} | {{ v.createUserPhone || '--' }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="v.status == 23">
                <div
                  v-for="(img, ind) in v.imgList"
                  :key="ind"
                  class="imgBox"
                  @click="openImgs(img)"
                >
                  <div class="imgName">
                    <span v-if="img.baseProductType == 8"
                      >{{ img.productParentName || '--' }} |
                      {{ img.parentProductSpecification || '--' }} |
                      {{ img.productName || '--' }}</span
                    >
                    <span v-else
                      >{{ img.productName || '--' }} | {{ img.number || '--'
                      }}{{ img.priceUnit || '--' }}</span
                    >
                  </div>
                  <div class="num">{{ img.fileNum }}张</div>
                  <el-image
                    style="width: 208px; height: 150px"
                    :src="img.url"
                    fit="fill"
                  ></el-image>
                </div>
              </div>
            </div>
          </div>
        </el-timeline-item>
      </el-timeline>
    </el-scrollbar>
    <el-empty v-else :image-size="200"></el-empty>
    <el-dialog title="图册" :visible.sync="dialogVisible" width="800px" :before-close="handleClose">
      <div class="dialogTitle">
        <div class="imgName">
          <span v-if="imgObj.baseProductType == 8"
            >{{ imgObj.productParentName || '--' }} |
            {{ imgObj.parentProductSpecification || '--' }} | {{ imgObj.productName || '--' }}</span
          >
          <span v-else
            >{{ imgObj.productName || '--' }} | {{ imgObj.number || '--'
            }}{{ imgObj.priceUnit || '--' }}</span
          >
        </div>
        <div v-if="!!remark" class="remark">
          {{
            imgObj.baseProductType == 6
              ? '修图要求'
              : imgObj.baseProductType == 7
              ? '设计要求'
              : '制作要求'
          }}：{{ remark }}
        </div>
      </div>
      <el-carousel
        style="padding-top: 10px"
        height="500px"
        :interval="5000"
        :autoplay="false"
        arrow="always"
        @change="imgChange"
      >
        <el-carousel-item v-for="(item, i) in imgData" :key="i" class="carousel-img">
          <el-image style="height: 100%" :src="item.url" fit="fill"></el-image>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>

<script>
import { getDetailServerRecord, getSelectListByCondition, getSelectStatList } from '@/api/order'
export default {
  components: {},
  props: {
    // serverReords: {
    //   type: Array,
    //   default: () => [],
    // },

    orderDetailInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      activities: [],
      serverReords: [],
      dialogVisible: false,
      imgData: [],
      imgObj: {},
      loading: false,
      remark: '',
    }
  },
  computed: {
    getColor() {
      return (key) => {
        switch (key) {
          case 5:
            return '#EC808D'
          case 6:
            return '#0079FE'
          case 7:
            return '#70B603'
          case 8:
            return '#0079FE'
          case 9:
            return '#70B603'
          default:
            return ''
        }
      }
    },
  },
  watch: {},
  mounted() {
    this.getDetailServerRecord()
  },
  methods: {
    imgChange(e) {
      this.remark = this.imgData[e].remark
    },
    async getDetailServerRecord() {
      try {
        this.loading = true
        const data =
          (await getDetailServerRecord({ data: this.orderDetailInfo.serviceId })).data || []
        const row = data.find((e) => e.status === 23)
        if (row) {
          await getSelectStatList({
            data: {
              serviceId: this.orderDetailInfo.serviceId,
            },
          }).then((res) => {
            row.subList.forEach((e) => {
              e.imgList = []
              res.data.forEach((v) => {
                if (e.id === v.serviceRecordId) {
                  e.imgList.push(v)
                }
              })
            })
          })
        }
        this.loading = false
        this.serverReords = data
      } catch (error) {
        this.loading = false
      }
    },
    detail(row) {
      this.$router.push({
        path: '/serviceManagement/subOrderDetail',
        query: { serviceItemId: row.serviceItemId },
      })
    },
    openImgs(row) {
      this.imgObj = row
      this.dialogVisible = true
      getSelectListByCondition({
        data: {
          serviceId: this.orderDetailInfo.serviceId,
          orderProductId: row.orderProductId,
        },
      }).then((res) => {
        this.imgData = res.data
        this.remark = this.imgData[0].remark
      })
    },
    handleClose() {
      this.dialogVisible = false
      this.imgData = []
    },
  },
}
</script>

<style lang="scss" scoped>
.el-carousel__container {
  position: relative;
  .remark {
    position: absolute;
    top: -50px;
    left: 300px;
    // background-color: #000 !important;
  }
}
.reason {
  word-break: break-all;
}
.server-record {
  padding: 24px;
  ::v-deep.el-timeline-item__timestamp {
    color: #333;
  }
  ::v-deep.el-timeline {
    padding: 0 4px;
  }
  .list {
    background-color: #f2f2f2;
    padding: 10px 16px;
    // width: 500px;
    color: #7f7f7f;
    font-size: 16px;
    .flex {
      justify-content: space-between;
    }
    .subList {
      .subItem {
        padding: 8px 0;
        display: flex;
        justify-content: space-between;
        // text-align: right;
      }
      .item {
        padding: 8px 0;
        line-height: 24px;
        font-size: 13px;
      }
      .msg {
        padding-top: 8px;
      }
    }
  }
}
.el-carousel__item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imgBox {
  width: 228px;
  height: 170px;
  background-color: #ccc;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  .num {
    min-width: 48px;
    min-height: 42px;
    line-height: 42px;
    text-align: center;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 6px;
    z-index: 1;
  }
  .imgName {
    z-index: 1;
    display: flex;
    align-items: center;
    width: 228px;
    box-sizing: border-box;
    top: 0;
    position: absolute;
    background-color: #00bfbf;
    padding: 10px;
    min-height: 38px;
    font-size: 13px;
    white-space: pre-wrap;
    word-break: break-all;
  }
}
::v-deep.el-dialog__body {
  padding: 10px 20px;
  .dialogTitle {
    display: flex;
    .imgName {
      box-sizing: border-box;
      background-color: #00bfbf;
      padding: 10px;
      color: #fff;
      font-size: 13px;
    }
    .remark {
      background-color: #fff;
      color: #999;
      padding-left: 70px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
``
