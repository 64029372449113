<template>
  <div class="aditional-purchase-info-container">
    <div class="flex aditional-purchase-info-operate">
      <div class="operate-l">加购信息</div>
    </div>
    <el-scrollbar ref="elScroll" class="scrollBox" style="height: 600px">
      <div v-if="addProductInfos.length > 0" class="order-list-container">
        <ul v-for="(item, i) in addProductInfos" :key="i" class="order-list">
          <li class="list-item">
            <div class="flex item-header" :style="getCurrentBgC(item.orderStatus)">
              <div class="item-header-l">
                <span>创建时间：{{ item.createTime }}</span>
                <span>加购单号：{{ item.orderNo }}</span>

                <span v-if="item.orderStatus == 1 && item.overdueTime && item.payType == 1">
                  剩余：
                  <Timer :remain-time="item.overdueTime" @countDowmEnd="closeOrder(item.orderId)" />
                  自动取消
                </span>
                <span v-if="item.managerName">销售顾问：{{ item.managerName }}</span>
              </div>
              <div class="item-header-r">{{ item.orderStatusName }}</div>
            </div>
            <div class="flex item-content">
              <div class="item-common item-content-img">
                <div
                  v-for="(itemS, s) in item.productListDtoList"
                  :key="s + 's'"
                  :style="{
                    borderBottom: s + 1 != item.productListDtoList.length ? '1px solid #e0e0e0' : '',
                  }"
                >
                  <ul class="flex item-content-img-item">
                    <el-image class="item-content-img-l" :src="itemS.coverPictureUrl" alt="">
                      <span slot="error" class="el-image-error">暂无图片</span>
                    </el-image>
                    <li class="item-content-img-r">
                      <div class="flex img-r-info">
                        <span class="img-r-info-title">{{ itemS.productName }}</span>
                        <span class="img-r-info-text">￥{{ itemS.salePrice }}</span>
                      </div>
                      <div class="flex img-r-info">
                        <span class="img-r-info-text"></span>
                        <span class="img-r-info-text">x{{ itemS.number }}</span>
                      </div>
                      <div class="flex img-r-info">
                        <span v-if="itemS.baseProductType == 9 || itemS.baseProductType == 16" class="img-r-info-text">相纸页数：{{ itemS.designNum }}</span>
                        <span class="img-r-info-text">规格：{{ itemS.specification }}</span>
                      </div>
                      <div class="flex img-r-info">
                        <span v-if="itemS.baseProductType == 9 || itemS.baseProductType == 16" class="img-r-info-text">照片张数：{{ itemS.photos }}</span>
                        <span class="img-r-info-text">计费单位：{{ itemS.priceUnit }}</span>
                      </div>
                    </li>
                  </ul>
                  <div v-if="itemS.dtoList && itemS.dtoList.length > 0">
                    <ul v-for="(n, index) in itemS.dtoList" :key="index" class="flex item-content-img-item">
                      <el-image class="item-content-img-l" :src="n.coverPictureUrl" alt="">
                        <span slot="error" class="el-image-error">暂无图片</span>
                      </el-image>
                      <li class="item-content-img-r">
                        <div class="flex img-r-info">
                          <span class="img-r-info-title">{{ n.productName }}</span>
                          <span class="img-r-info-text">￥{{ n.salePrice }}</span>
                        </div>
                        <div class="flex img-r-info">
                          <span class="img-r-info-text"></span>
                          <span class="img-r-info-text">x{{ n.number }}</span>
                        </div>
                        <div class="flex img-r-info">
                          <span class="img-r-info-text">规格：{{ n.specification }}</span>
                        </div>
                        <div class="flex img-r-info">
                          <span class="img-r-info-text">计费单位：{{ n.priceUnit }}</span>
                          <span v-if="n.refundStatus == 1" class="img-r-info-text" style="color: #e86247">申请退款</span>
                          <span v-if="n.refundStatus == 3" class="img-r-info-text" style="color: #e86247">已退款:￥{{ n.refundAmount }}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div style="text-align: right; padding: 0 20px 10px 0">
                    <span v-if="itemS.refundStatus == 1" class="img-r-info-text" style="color: #e86247">申请退款</span>
                    <span v-if="itemS.refundStatus == 3" class="img-r-info-text" style="color: #e86247">已退款:￥{{ itemS.refundAmount }}</span>
                  </div>
                </div>
              </div>
              <div class="item-common item-money">
                <div class="item-lable" style="font-size: 18px">实付：￥{{ item.payAmount }}</div>
                <div class="item-text">合计：￥{{ item.amount }}</div>
                <div class="item-text">优惠：￥{{ item.discountAmount }}</div>
              </div>

              <div class="item-common item-content-butoon">
                <el-button v-if="menus.find((e) => e.nodeCode == 'GY_ORDER_ADDORDER_DETAIL')" class="button-detail" type="primary" @click="handleOperate('serachDetail', item)"
                  >查看详情</el-button
                >
                <el-button v-if="item.orderStatus == 1 && item.payType == 1" type="primary" @click="handleOperate('searchPayLink', item)">查看付款码</el-button>
                <el-button v-if="item.payType == 2 && item.isPay != 1 && (item.orderStatus == 1 || item.orderStatus == 2)" size="small" type="primary" @click="onOfflinePay(item)"
                  >收款登记</el-button
                >
                <el-button v-if="item.orderStatus == 1 && item.isPay == 0 && item.payType == 1" class="refuse" type="primary" @click="handleOperate('cancelOrder', item)">取消订单</el-button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <el-empty v-else :image-size="200"></el-empty>
    </el-scrollbar>
    <CancelOrderPopups popups-title="取消订单" :current-data="currentData" :show-state="isCancelOrder" @refresh="refresh" @hidePopups="isCancelOrder = false" />
    <SearchPayOrderPopups popups-title="请客户扫描下方付款码完成付款" :current-data="currentData" :show-state="isSearchPayOrder" @hidePopups="isSearchPayOrder = false" />

    <ReceiveAuditDialog v-if="showOfflinePay" :dialog-visible.sync="showOfflinePay" :item="auditOrderItem" @refresh="refresh" />
  </div>
</template>

<script>
import CancelOrderPopups from './cancelOrder'
import SearchPayOrderPopups from './searchPayOrder'
import Timer from './timer.vue'
import { cancelOrder } from '@/api/order'

export default {
  components: {
    CancelOrderPopups,
    SearchPayOrderPopups,
    Timer,
  },
  props: {
    orderDetailInfo: {
      type: Object,
      default: () => ({}),
    },
    addProductInfos: {
      type: Array,
      default: () => [],
    },
    currenTab: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isAddProduct: false,
      isCancelOrder: false,
      isSearchPayOrder: false,
      currentData: {},
      menus: [],
      showOfflinePay: false,
      auditOrderItem: {},
    }
  },
  computed: {
    getCurrentBgC() {
      return (status) => {
        let sty = {}
        switch (status) {
          case 1:
            sty = { backgroundColor: '#fcefec', color: '#E86247' }
            break
          case 2:
          case 3:
            sty = { backgroundColor: '#FCF3DE', color: '#E3904A' }
            break
          case 4:
            sty = { backgroundColor: '#E9EFFC', color: '#2861E2' }
            break
          case 5:
          case 6:
            sty = { backgroundColor: '#EDEDED', color: '#000000' }
            break
          case 7:
          case 8:
            sty = { backgroundColor: '#E5F5F4', color: '#00A199' }
            break
        }
        return sty
      }
    },
  },
  mounted() {
    this.menus = JSON.parse(window.sessionStorage.getItem('btnPermissions')) ? JSON.parse(window.sessionStorage.getItem('btnPermissions')) : []
  },
  methods: {
    async onOfflinePay(item) {
      this.showOfflinePay = true
      this.auditOrderItem = item
    },
    refresh() {
      this.$parent.getDetailAddOrder()
    },
    handleOperate(mark, item) {
      switch (mark) {
        case 'serachDetail':
          this.$router.push({
            path: '/aditionalPurchaseInfoDetail',
            query: { serviceId: this.$route.query.serviceId, orderId: item.orderId },
          })
          break
        case 'searchPayLink':
          this.currentData = item
          this.isSearchPayOrder = true
          break
        case 'cancelOrder':
          this.currentData = item
          this.isCancelOrder = true
          break
      }
    },
    closeOrder(e) {
      cancelOrder({
        data: { orderId: e, cancelReason: '订单到期,自动取消' },
      }).then((res) => {
        if (res.data) {
          this.$message.success('取消成功')
          this.refresh()
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.aditional-purchase-info-container {
  padding: 24px 20px;
  .aditional-purchase-info-operate {
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;
    .operate-l {
      font-weight: 500;
      font-size: 14px;
    }

    .refuse {
      background-color: #fff;
      color: #2861e2;
      border: 1px solid #2861e2;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .order-list-container {
    background-color: #fff;
    font-size: 14px;
    .order-list {
      margin-bottom: 20px;
      border: 1px solid #e0e0e0;

      .list-item {
        .item-header {
          padding: 13px 17px;
          justify-content: space-between;
          color: #363f47;
          .item-header-l {
            span:nth-of-type(2) {
              margin: 30px;
            }
          }
        }
        .item-content {
          .item-common {
            flex: 1;
            padding: 16px;
            border-right: 1px solid #e0e0e0;
          }
          .item-common:last-child {
            border: none;
          }
          .item-lable {
            margin-bottom: 12px;
            font-weight: 500;
          }
          .item-text {
            margin-bottom: 12px;
          }
          .item-img-text {
            margin-bottom: 12px;
            color: #666666;
          }
          .item-content-img {
            padding: 0;
            .item-content-img-item {
              padding: 20px;
              // border-bottom: 1px solid #e0e0e0;
              .item-content-img-l {
                width: 80px;
                height: 80px;
                margin-right: 20px;
                vertical-align: middle;
              }
              .item-content-img-r {
                width: 100%;
                .img-r-info {
                  justify-content: space-between;
                  .img-r-info-title {
                    font-weight: 500;
                  }
                  .img-r-info-text {
                    margin-bottom: 4px;
                  }
                }
              }
              &:last-child {
                border: none;
              }
            }
          }
          .item-money {
            max-width: 300px;
          }
          .item-content-butoon {
            max-width: 160px;
            ::v-deep .el-button {
              display: block;
              margin: 0 0 12px 0;
              width: 100%;
            }
            ::v-deep .el-button:last-child {
              margin: 0;
            }
            .button-detail {
              background-color: #e9effc;
              color: #2861e2;
              border: 1px solid #e9effc;
              &:hover {
                opacity: 0.8;
              }
            }
            .refuse {
              background-color: #fff;
              color: #2861e2;
              border: 1px solid #2861e2;
              &:hover {
                opacity: 0.8;
              }
            }
          }
        }
      }
    }
    .pagination {
      padding: 20px 0 24px 0;
      text-align: right;
    }
  }
}
</style>
