<template>
  <div class="server-order-info">
    <div class="server-information-title">服务信息</div>
    <div class="server-information">
      <div v-if="!!orderDetailInfo.orderProduct" class="flex server-information-block">
        <!-- <el-image class="server-information-images" :src="orderDetailInfo.orderProduct.coverPictureUrl" alt=""><span class="el-image-error" slot="error">暂无图片</span></el-image> -->

        <ViewSamplePhotos
          v-if="showSamplePhoto"
          :cover-picture-url="orderDetailInfo.coverPictureUrl"
          :order-sample-pic-list="orderDetailInfo.orderSamplePicList"
          :questionnaire-sample-pic-list="orderDetailInfo.questionnaireSamplePicList"
          :qphoto-regist-sample-pic-list="orderDetailInfo.qphotoRegistSamplePicList"
        ></ViewSamplePhotos>

        <div>
          <div class="server-information-name">
            {{ orderDetailInfo.orderProduct.productName }}（ID：{{
              orderDetailInfo.orderProduct.productCode
            }}）
          </div>
          <div class="server-information-id"></div>
          <div class="server-information-id">
            服务类型：{{ orderDetailInfo.orderProduct.serviceTypeName }}
          </div>
          <div class="server-information-id">
            所属品牌：{{
              orderDetailInfo.orderProduct.brandName
                ? orderDetailInfo.orderProduct.brandName
                : '暂无品牌'
            }}
          </div>
        </div>
      </div>
      <el-descriptions
        v-if="
          orderDetailInfo.orderProduct &&
          orderDetailInfo.orderProduct.subList &&
          orderDetailInfo.orderProduct.subList.length
        "
        :colon="false"
        :column="1"
      >
        <el-descriptions-item v-for="(item, i) in orderDetailInfo.orderProduct.subList" :key="i">
          <template slot="label">
            <div
              v-if="item.baseProductType != 9 || !item.subList || item.subList.length == 0"
              class="flex"
            >
              <span>{{ item.productName }}</span>
              <div v-if="!item.mountList" style="color: #999">
                {{ item.number + item.priceUnit }}
              </div>
            </div>
            <div
              v-if="item.baseProductType == 9 && item.subList && item.subList.length > 0"
              style="flex: 1"
            >
              <div
                v-for="(n, index) in item.subList"
                :key="index"
                class="flex"
                :style="{
                  paddingBottom: index + 1 == item.subList.length ? '' : '8px',
                }"
              >
                <span class="subItem">
                  {{ item.productName }}
                  <span
                    v-if="item.baseProductType == 9 && item.subList && item.subList.length > 0"
                    style="color: #999"
                  >
                    {{ n.number + item.priceUnit }}
                  </span>
                </span>

                <span class="subItem" style="padding-left: 40px">
                  <span>
                    <i
                      style="padding-right: 20px; font-size: 16px; font-weight: bold"
                      class="el-icon-connection"
                    ></i>
                    <span>{{ n.productName }}</span>
                  </span>
                  <span style="padding-left: 10px; color: #999">
                    {{ n.number + n.priceUnit }}
                  </span>
                </span>
              </div>
            </div>
          </template>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div style="margin: 20px 0">
      <div class="server-information-title">库存基础产品</div>
      <BaseProductTable :data-list="inventoryPorduct" :is-price="false" />
    </div>
    <el-descriptions title="预约信息" :column="2" border :label-style="{ width: '120px' }">
      <el-descriptions-item label="预约人">{{
        orderDetailInfo.contactPersonName
      }}</el-descriptions-item>
      <el-descriptions-item label="联系方式">{{
        orderDetailInfo.contactPhoneNumber
      }}</el-descriptions-item>
      <el-descriptions-item label="拍摄时间">{{
        orderDetailInfo.appointmentShootDatetime
      }}</el-descriptions-item>
      <el-descriptions-item v-if="orderDetailInfo.serviceType == 2" label="拍摄地点">
        <div class="flex">
          <span>{{ orderDetailInfo.appointmentStroeAddress }}&emsp;</span>
          <el-link
            :underline="false"
            type="primary"
            @click="copyCode(orderDetailInfo.appointmentStroeAddress)"
            >复制地址</el-link
          >
          <i class="el-icon-location adress" @click="goMap"></i>
          <span style="color: #7f7f7f">距离门店：{{ orderDetailInfo.distance }}</span>
        </div>
      </el-descriptions-item>
      <!-- <el-descriptions-item v-else></el-descriptions-item> -->
      <el-descriptions-item label="预约备注">{{
        orderDetailInfo.shootRemark
      }}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions title="支付信息" :column="1" border :label-style="{ width: '120px' }">
      <el-descriptions-item label="服务金额"
        >￥{{ orderDetailInfo.payAmount || '0.00' }}</el-descriptions-item
      >
      <el-descriptions-item label="库存金额"
        >￥{{ orderDetailInfo.inventoryAmount || '0.00' }}</el-descriptions-item
      >
      <!-- <el-descriptions-item label="套系加购金额">￥{{ orderDetailInfo.addAmount || '0.00' }}</el-descriptions-item> -->
      <el-descriptions-item label="加购金额"
        >￥{{ orderDetailInfo.addRefundAmount || '0.00' }}</el-descriptions-item
      >
      <el-descriptions-item label="服务总金额"
        >￥{{ orderDetailInfo.totalAmount || '0.00' }}</el-descriptions-item
      >
    </el-descriptions>
    <el-descriptions title="销售顾问" :column="1" border :label-style="{ width: '120px' }">
      <el-descriptions-item label="销售顾问">{{
        orderDetailInfo.managerName || ''
      }}</el-descriptions-item>
    </el-descriptions>
    <myMap
      :map-visible="mapVisible"
      :adress="orderDetailInfo.appointmentPostAddress"
      :lng="orderDetailInfo.appointmentLng"
      :lat="orderDetailInfo.appointmentLat"
      @close="mapVisible = false"
    />
  </div>
</template>

<script>
import myMap from './map.vue'
import { getServiceInventory } from '@/api/order.js'
export default {
  components: {
    myMap,
  },
  props: {
    orderDetailInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      mapVisible: false,
      inventoryPorduct: [],
    }
  },
  computed: {
    calculateAllMoney() {
      return (
        Number(this.orderDetailInfo.payAmount) +
        Number(this.orderDetailInfo.addAmount) -
        Number(this.orderDetailInfo.refundAmount)
      ).toFixed(2)
    },
    showSamplePhoto() {
      return Object.keys(this.orderDetailInfo).length > 2
    },
  },
  watch: {
    orderDetailInfo: {
      handler(val) {
        if (!val.serviceId) return
        this.getServiceInventory()
      },
      immediate: true,
    },
  },
  methods: {
    copyCode(data) {
      // 存储传递过来的数据
      const OrderNumber = data
      // 创建一个input 元素
      // createElement() 方法通过指定名称创建一个元素
      const newInput = document.createElement('input')
      // 讲存储的数据赋值给input的value值
      newInput.value = OrderNumber
      // appendChild() 方法向节点添加最后一个子节点。
      document.body.appendChild(newInput)
      // 选中input元素中的文本
      // select() 方法用于选择该元素中的文本。
      newInput.select()
      // 执行浏览器复制命令
      //  execCommand方法是执行一个对当前文档，当前选择或者给出范围的命令
      document.execCommand('Copy')
      // 清空输入框
      newInput.remove()
      // 下面是element的弹窗 不需要的自行删除就好
      this.$message({
        message: '复制成功',
        type: 'success',
      })
    },
    goMap() {
      this.mapVisible = true
    },
    getServiceInventory() {
      getServiceInventory({
        data: {
          serviceId: this.orderDetailInfo.serviceId,
          scene: 4,
        },
      }).then((res) => {
        console.log(res)
        this.inventoryPorduct = res.data.map((e) => {
          e.mountList = [e.mountingProduct]
          return e
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep.el-descriptions-item__content {
  .flex {
    align-items: center;
    .adress {
      cursor: pointer;
      margin: 0 8px;
      font-size: 20px;
    }
  }
}
::v-deep.el-descriptions-item__container {
  justify-content: space-between;
  padding-right: 200px;
  .el-descriptions-item__label {
    color: #333;
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding-right: 40px;
    .flex {
      flex: 1;
      justify-content: space-between;
    }
    .subItem {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .el-descriptions-item__content {
    display: none;
  }
}
.server-order-info {
  padding: 22px 100px 20px 20px;
  .server-information-title {
    margin: 0 0 12px 0;
    font-size: 14px;
    font-weight: 500;
  }
  .server-information {
    padding: 20px;
    // width: 900px;
    background-color: #f5f5f5;
    .el-descriptions {
      width: 820px;
    }
    .server-information-block {
      margin-bottom: 16px;
      font-size: 14px;
      .server-information-images {
        width: 80px;
        height: 80px;
        margin-right: 20px;
      }
      .server-information-name {
        margin-bottom: 10px;
        font-weight: bold;
        color: #333;
      }
      .server-information-id {
        color: #333;
        font-size: 13px;
      }
    }
  }
}
</style>
