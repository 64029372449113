<template>
  <div class="order-detail-container">
    <div v-loading="boxLoading" class="order-header">
      <div class="header-title">
        <span>服务订单号：{{ detailsInfo.serviceNo }}</span>
        <span
          v-if="detailsInfo.status === 2"
          class="request-refund"
          style="background-color: #fcefec; color: #e86247"
          >{{ detailsInfo.statusName }}</span
        >
        <span
          v-else-if="detailsInfo.status === 3"
          class="request-refund"
          style="background-color: #fcf3de; color: #e3904a"
          >{{ detailsInfo.statusName }}</span
        >
        <span
          v-else-if="detailsInfo.status === 4"
          class="request-refund"
          style="background-color: #e9effc; color: #2861e2"
          >{{ detailsInfo.statusName }}</span
        >
        <span
          v-else-if="detailsInfo.status === 5"
          class="request-refund"
          style="background-color: #ededed; color: #000000"
          >{{ detailsInfo.statusName }}</span
        >
        <span
          v-else-if="detailsInfo.status === 8"
          class="request-refund"
          style="background-color: #e5f5f4; color: #00a199"
          >{{ detailsInfo.statusName }}</span
        >
        <span v-else class="request-refund" style="background-color: #999; color: #fff">{{
          detailsInfo.statusName
        }}</span>
      </div>
      <div class="info">
        <div class="info-item">
          <label class="info-item__label">派单时间</label>
          <div class="info-item__value">{{ detailsInfo.dispatchTime }}</div>
        </div>
        <div class="info-item">
          <label class="info-item__label">预约提交时间</label>
          <div class="info-item__value">{{ detailsInfo.applyAppointmentTime }}</div>
        </div>
        <div v-if="!!detailsInfo.confirmOrderTime" class="info-item">
          <label class="info-item__label">接单时间</label>
          <div class="info-item__value">{{ detailsInfo.confirmOrderTime }}</div>
        </div>
        <div
          v-if="detailsInfo.serviceType == 2 && !!detailsInfo.confirmOrderTime"
          class="info-item"
        >
          <label class="info-item__label">开始服务时间</label>
          <div class="info-item__value">{{ detailsInfo.confirmOrderTime }}</div>
        </div>
        <div
          v-if="detailsInfo.serviceType == 1 && !!detailsInfo.confirmStoreTime"
          class="info-item"
        >
          <label class="info-item__label">到店时间</label>
          <div class="info-item__value">{{ detailsInfo.confirmStoreTime }}</div>
        </div>
        <div v-if="!!detailsInfo.finishTime" class="info-item">
          <label class="info-item__label">服务完成时间</label>
          <div class="info-item__value">{{ detailsInfo.finishTime }}</div>
        </div>
        <div class="info-item">
          <label class="info-item__label">所属业务线</label>
          <div class="info-item__value">
            {{ detailsInfo.bizLineId == '2' ? 'JPG' : '大片来了' }}
          </div>
        </div>
      </div>
      <div class="header-button-group">
        <el-button
          v-if="detailsInfo.status === 2"
          size="small"
          type="primary"
          @click="handleOperate('enterOrder')"
          >确认接单</el-button
        >
        <el-button
          v-if="
            !detailsInfo.buttonV2Dto.hiddQrddBtn &&
            detailsInfo.orderProduct.serviceType == 1 &&
            detailsInfo.subStatus === 4
          "
          size="small"
          type="primary"
          class="bg00"
          @click="handleOperate('enterStop')"
        >
          确认到店
        </el-button>
        <!-- <el-button type="primary" v-if="detailsInfo.serviceType == 2 && (detailsInfo.status === 3 || detailsInfo.status === 4)" @click="lookCode">查看验证码</el-button> -->
        <!-- <el-button type="primary" @click="handleOperate('enterServer')" v-if="detailsInfo.serviceType == 1 && detailsInfo.status === 4">确认完成服务</el-button> -->
        <el-button
          v-if="detailsInfo.status != 2"
          size="small"
          type="primary"
          @click="handleOperate('uploadImg')"
          >上传照片/视频</el-button
        >
        <el-button
          v-if="detailsInfo.status === 2"
          size="small"
          class="refuse"
          type="primary"
          @click="handleOperate('refuseOrder')"
          >拒绝接单</el-button
        >
        <el-button class="refuse" type="primary" size="small" @click="gotoAddPayOrder(detailsInfo)"
          >新增订单</el-button
        >
        <el-button
          v-if="[3, 4, 5, 8].includes(detailsInfo.status)"
          class="refuse"
          type="primary"
          size="small"
          @click="handleOperate('addProduct')"
          >加购产品</el-button
        >
        <el-button
          v-if="detailsInfo.buttonV2Dto && detailsInfo.buttonV2Dto.selectInventoryBtn"
          size="small"
          type="primary"
          @click="onShowSubDialog(detailsInfo)"
        >
          选择库存散件
        </el-button>
        <el-button v-if="detailsInfo.status === 1" type="primary" @click="subscribe"
          >预约门店</el-button
        >

        <Btns
          style="padding-left: 10px; margin-right: 10px"
          type="detail"
          :item="detailsInfo"
          @handleTab="handleTab"
          @refresh="refresh"
        />

        <el-button
          v-if="hasPermission('SHOOT_SERVICE')"
          class="refuse"
          size="small"
          type="primary"
          @click="openServiceList"
          >关联服务单</el-button
        >
      </div>
    </div>
    <div v-loading="boxLoading" class="header-content">
      <ul class="flex tab-list">
        <li
          v-for="(item, i) in tabList"
          :key="i"
          class="tab-list-item"
          :class="item.checked ? 'item-checked' : ''"
          @click="handleTab(item)"
        >
          {{ item.name }}
          <span v-if="item.num">({{ item.num }})</span>
        </li>
      </ul>
      <div class="header-tab-content">
        <detailServerOrderInfo
          v-if="currenTab.name === '服务单信息'"
          :order-detail-info="detailsInfo"
        />
        <inventory-record
          v-if="currenTab.name === '库存选择记录'"
          :id="detailsInfo.serviceId"
          ref="inventory"
        />
        <detailAditionalPurchaseInfo
          v-else-if="currenTab.name === '加购信息'"
          v-loading="addProductLoading"
          :order-detail-info="detailsInfo"
          :curren-tab="currenTab"
          :add-product-infos="addProductInfos"
        />
        <detailSubOrder
          v-else-if="currenTab.name === '子服务订单'"
          ref="detailSubOrder"
          :service-id="detailsInfo.serviceId"
          @refresh="refresh"
        />
        <detailServerRecord
          v-else-if="currenTab.name === '服务记录'"
          v-loading="serverReordsLoading"
          :server-reords="serverReords"
          :order-detail-info="detailsInfo"
        />
        <detailServerProtocol
          v-else-if="currenTab.name === '服务协议'"
          :server-protocol="serverProtocol"
        />
        <detailPhotos
          v-else-if="currenTab.name === '影像库'"
          ref="detailPhotos"
          v-loading="photosLoading"
          :current-data="detailsInfo"
        />
        <imageMuseum
          v-else-if="currenTab.name === '影像馆'"
          ref="imageMuseum"
          v-loading="photosLoading"
          :current-data="detailsInfo"
        />
        <detailServerEvaluation
          v-else-if="currenTab.name === '服务评价'"
          v-loading="evaluationLoading"
          :order-evaluation-detail="orderEvaluationDetail"
          :surroundings="surroundings"
          :servers-rate="serversRate"
          @refresh="getEvaluation"
        />
        <beforeShoot
          v-if="currenTab.name === '新品推荐'"
          :biz-line-id="detailsInfo.bizLineId"
          :service-no="detailsInfo.serviceNo"
          :customer-id="detailsInfo.customerId"
          :service-id="detailsInfo.serviceId"
          :user-phone="detailsInfo.contactPhoneNumber"
          :product-id="detailsInfo.orderProduct.productId"
          :user-id="detailsInfo.userId"
        />
        <photographer
          v-if="currenTab.name === '新品推荐'"
          :biz-line-id="detailsInfo.bizLineId"
          :service-id="detailsInfo.serviceId"
          :user-id="detailsInfo.userId"
          :customer-id="detailsInfo.customerId"
          :friends-num="detailsInfo.friendsNum"
          :status="detailsInfo.status"
          @reset="onReset"
        />
        <shootPlan v-if="currenTab.name === '拍摄规划'" />
      </div>
    </div>
    <RefuseOrderPopups
      popups-title="拒绝接单原因"
      :show-state="isRefuseOrder"
      :current-data="currentData"
      @hidePopups="isRefuseOrder = false"
    />
    <SuccessServerPopups
      popups-title="确认完成服务"
      :show-state="isSuccessServer"
      :current-data="currentData"
      @hidePopups="isSuccessServer = false"
    />
    <UploadPhotoPopups
      v-if="isUploadPhoto"
      popups-title="上传照片"
      :show-state="isUploadPhoto"
      :current-data="currentData"
      @hidePopups="isUploadPhoto = false"
    />
    <lookCode
      :dialog-visible.sync="dialogVisible"
      :service-no="detailsInfo.serviceNo"
      :service-id="detailsInfo.serviceId"
    />

    <AddBuyOrder
      popups-title="新增加购产品"
      :show-state.sync="isAddProduct"
      :current-data="currentData"
      :curren-tab="currenTab"
    />

    <!-- 关联服务单 -->
    <SelectedInventory
      :service-id="currentData.serviceId"
      :show-product.sync="showSubDialog"
      @success="onSuccess"
    />
    <relatedServiceOrder v-model="outerVisible" :details-info="detailsInfo" @reFresh="reFresh" />
  </div>
</template>

<script>
import lookCode from '@/views/orderMgt/orderList/components/lookCode'
import RefuseOrderPopups from './components/refuseOrder'
import SuccessServerPopups from './components/successServer'
import UploadPhotoPopups from './components/uploadPhoto'
import InventoryRecord from '@/views/orderMgt/orderList/components/inventory-record/index.vue'
import relatedServiceOrder from '@/views/orderMgt/orderList/components/relatedServiceOrder.vue'

import detailServerOrderInfo from './components/detailServerOrderInfo'
import detailAditionalPurchaseInfo from './components/detailAditionalPurchaseInfo'
import detailServerRecord from './components/detailServerRecord'
import detailServerProtocol from './components/detailServerProtocol'
import detailPhotos from './components/detailPhotos'
import imageMuseum from './components/imageMuseum'
import detailServerEvaluation from './components/detailServerEvaluation'
import detailSubOrder from './serviceitems/detailSubOrder'
import { getDictionaryList } from '@/api/common'
import {
  getOrderDetail,
  getDetailAddOrderList,
  getDetailServerProtocol,
  enterOrder,
  enterStore,
  getDetailEvaluation,
  storeGetList,
} from '@/api/order'
import Btns from './components/btns.vue'
import beforeShoot from './components/beforeShoot.vue'
import photographer from './components/photographer.vue'
import shootPlan from './components/shootPlan.vue'
import { hasPermission } from '@/utils/buttonAuthority'

export default {
  components: {
    RefuseOrderPopups,
    SuccessServerPopups,
    UploadPhotoPopups,
    detailSubOrder,
    detailServerOrderInfo,
    detailAditionalPurchaseInfo,
    detailServerRecord,
    detailServerProtocol,
    detailPhotos,
    detailServerEvaluation,
    lookCode,
    imageMuseum,
    Btns,
    beforeShoot,
    photographer,
    shootPlan,
    InventoryRecord,
    relatedServiceOrder,
  },
  data() {
    return {
      dialogVisible: false,
      orderServiceId: undefined,
      serviceId: undefined,
      boxLoading: false,
      serverReordsLoading: false,
      photosLoading: false,
      evaluationLoading: false,
      addProductLoading: false,
      detailsInfo: {
        buttonV2Dto: {},
        orderProduct: {},
      },
      addProductInfos: {}, // 加购信息
      serverReords: [], // 服务记录
      serverProtocol: {}, // 服务协议
      photos: [], // 影像库
      connectPhone: '', //  影像库-联系电话
      orderEvaluationDetail: {}, // 订单评价信息
      surroundings: {},
      serversRate: [],
      tabList: [
        { name: '服务单信息', num: 0, checked: false },
        { name: '库存选择记录', num: 0, checked: false },
        { name: '加购信息', num: 0, checked: false },
        { name: '子服务订单', num: 0, checked: false },

        { name: '服务记录', num: 0, checked: false },
        // { name: '服务协议', num: 0, checked: false },
        { name: '影像库', num: 0, checked: false },
        { name: '影像馆', num: 0, checked: false },
        { name: '服务评价', num: 0, checked: false },
        { name: '新品推荐', num: 0, checked: false },
        { name: '拍摄规划', num: 0, checked: false },
      ],
      currenTab: { name: '服务单信息', num: 0, checked: true },
      isRefuseOrder: false,
      isSuccessServer: false,
      isAddProduct: false,
      isUploadPhoto: false,
      currentData: {},
      friendsNum: '',
      outerVisible: false,
      pageNumber: 1,
      pageSize: 10,
      loading: false,
      total: 1,
      groupServiceId: '',
      groupServiceNo: '',
      resGroupServiceId: '',
      showSubDialog: false,
    }
  },

  async mounted() {
    await this.getData()
    if (this.$route.query.tabIndex) {
      this.currenTab = this.tabList[this.$route.query.tabIndex]
      this.currenTab.checked = true
    } else {
      this.tabList[0].checked = true
    }
  },

  methods: {
    onShowSubDialog(item) {
      this.currentData = item
      this.showSubDialog = true
    },
    hasPermission,
    onReset() {
      this.getData()
    },
    async refresh() {
      this.detailsInfo = (await getOrderDetail({ data: this.serviceId })).data || {}
      if (this.currenTab.name === '子服务订单') this.$refs.detailSubOrder.getTableData()
    },
    lookCode() {
      this.dialogVisible = true
    },
    async getData() {
      try {
        this.boxLoading = true

        this.orderServiceId = this.$route.query.orderServiceId
        this.serviceId = this.$route.query.serviceId

        this.detailsInfo = (await getOrderDetail({ data: this.serviceId })).data || {}

        this.addProductInfos =
          (
            await getDetailAddOrderList({
              data: { serviceId: this.detailsInfo.serviceId, isAddition: 1 },
            })
          ).data.content || []
        if (this.addProductInfos.length) {
          const index = this.tabList.findIndex((e) => e.name === '加购信息')
          this.tabList[index].num = this.addProductInfos.length
        }

        const index = this.tabList.findIndex((e) => e.name === '子服务订单')
        this.tabList[index].num = (
          await storeGetList({
            pageNumber: 1,
            pageSize: 1,
            data: {
              serviceId: this.serviceId,
            },
          })
        ).data.total
        if (this.$route.query.isServer) {
          const currentItem = this.tabList.find((fi) => fi.checked)
          currentItem.checked = false
          this.tabList[1].checked = true
          this.currenTab = this.tabList[1]
          this.getDetailAddOrder()
        }
        if (this.currenTab.name === '子服务订单') this.$refs.detailSubOrder.getTableData()
        this.boxLoading = false
      } catch (error) {
        this.boxLoading = false
      }
    },

    async getDetailAddOrder() {
      try {
        this.addProductLoading = true

        const addProductRes =
          (
            await getDetailAddOrderList({
              pageNumber: 1,
              pageSize: 999,
              data: { serviceId: this.detailsInfo.serviceId, isAddition: 1 },
            })
          ).data.content || []

        this.addProductInfos = await addProductRes

        if (this.addProductInfos.length) {
          const index = this.tabList.findIndex((e) => e.name === '加购信息')
          this.tabList[index].num = this.addProductInfos.length
        }
        this.addProductLoading = false
      } catch (error) {
        console.log(error)
        this.addProductLoading = false
      }
    },
    getDetailPhotos() {
      this.$refs.detailPhotos.getInfo()
    },
    onSuccess() {
      this.$refs.inventory.getServiceInventoryRecord()
    },

    async handleTab(item) {
      const currenObj = this.tabList.find((item) => item.checked)
      currenObj.checked = false
      const obj = this.tabList.find((e) => item.name === e.name)

      obj.checked = !obj.checked
      this.$forceUpdate()
      this.currenTab = item
      switch (item.name) {
        case '服务单信息':
          this.detailsInfo = (await getOrderDetail({ data: this.serviceId })).data || {}
          break
        case '加购信息':
          this.getDetailAddOrder()

          break
        case '子服务订单':
          this.refresh()
          this.getData()
          break
        case '服务记录':
          try {
            this.serverReordsLoading = true

            this.serverReordsLoading = false
          } catch (error) {
            this.serverReordsLoading = false
          }
          break
        case '服务协议':
          this.serverProtocol =
            (await getDetailServerProtocol({ data: this.detailsInfo.orderId })).data || {}
          break
        case '影像库':
          // this.getDetailPhotos();
          break
        case '服务评价':
          await this.getEvaluation()
          break
      }
    },
    async getEvaluation() {
      try {
        this.evaluationLoading = true
        this.orderEvaluationDetail =
          (await getDetailEvaluation({ data: this.detailsInfo.serviceId })).data || {}

        this.orderEvaluationDetail.evaluationPictureUrl = JSON.parse(
          this.orderEvaluationDetail.evaluationPictureUrl,
        )
        this.orderEvaluationDetail.suggestPictureUrl = JSON.parse(
          this.orderEvaluationDetail.suggestPictureUrl,
        )

        // 超星满意点
        if (this.orderEvaluationDetail.satisfactions) {
          this.orderEvaluationDetail.satisfactions =
            this.orderEvaluationDetail.satisfactions.split(',')
        }

        const surroundings =
          (await getDictionaryList({ data: { key: 'environment_score_type' } })).data || {}
        // let serversRate = (await getDictionaryList({ data: { key: 'service_socre_type' } })).data || {};
        const serversRate = []

        // eslint-disable-next-line no-extra-boolean-cast
        if (!!this.orderEvaluationDetail.evaluationScore) {
          this.orderEvaluationDetail.evaluationScore.forEach((item) => {
            if (!(item.scoreItemId <= 104 && item.scoreItemId >= 101)) serversRate.push(item)
            // surroundings.childs.forEach((itemJ) => {
            //   if (item.scoreItemId == itemJ.dictionaryValue) {
            //     itemJ.score = item.score;
            //   }
            // });
          })
        }
        this.surroundings = surroundings
        this.serversRate = serversRate
        this.evaluationLoading = false
      } catch (error) {
        this.evaluationLoading = false
      }
    },
    async handleOperate(type) {
      const item = { ...this.detailsInfo }
      const h = this.$createElement
      switch (type) {
        case 'enterOrder':
          this.$msgbox({
            message: h('div', { style: 'text-align:center' }, [
              h('i', {
                class: 'el-icon-success',
                style: 'color:green;font-size:60px; margin:10px 10px 0 0',
              }),
              h('p', { style: 'font-weight:bold; color：#fff' }, '接单成功！'),
              h('p', {}, '请到待服务订单进行查看'),
            ]),
            showCancelButton: false,
            showConfirmButton: false,
            showClose: false,
          })
            .then(() => {})
            .catch(() => {})
          await enterOrder({ data: { serviceId: item.serviceId } })
          this.getData()
          break
        case 'refuseOrder':
          item.inType = 'detail'
          this.currentData = item
          this.isRefuseOrder = true
          break
        case 'enterStop':
          this.$msgbox({
            message: h('div', { style: 'padding:11px' }, [
              h('i', {
                class: 'el-icon-warning',
                style: 'color:#ECA000;font-size:24px; vertical-align: middle;',
              }),
              h(
                'span',
                {
                  style:
                    'font-weight: 500;color: #000000; margin:0 0 0 6px; vertical-align: middle;',
                },
                '确认到店吗',
              ),
              h(
                'p',
                { style: 'font-size:14px; color:#9A9D9E;margin:12px 0 0 30px' },
                '是否确定到店？',
              ),
            ]),
            showCancelButton: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            showClose: false,
          })
            .then(async () => {
              await enterStore({ data: { serviceId: item.serviceId } })
              this.$notify.success({ title: '成功', message: '到店成功', duration: 2000 })
              this.getData()
            })
            .catch(() => {})

          break
        case 'enterServer':
          item.inType = 'detail'
          this.currentData = item
          this.isSuccessServer = true
          break
        case 'addProduct':
          item.orderProduct = { productId: this.detailsInfo.orderProduct.productId }
          item.inType = 'detail'
          this.currentData = item
          this.isAddProduct = true
          break
        case 'uploadImg':
          item.inType = 'detail'
          this.currentData = item
          this.isUploadPhoto = true
          break
      }
    },
    gotoAddPayOrder(e) {
      this.$router.push({
        name: 'addPayOrder',
        query: {
          customerId: e.customerId,
          serviceId: e.serviceId,
          bizLineId: e.userId + ',' + (e.bizLineId || '1'),
          orderStatus: e.status,
          storeName: e.appointmentStroe,
          storeId: e.appointmentStroeId,
        },
      })
    },

    subscribe() {
      const { serviceId, orderProduct } = this.detailsInfo

      const origin = window.location.origin
      window.location.assign(
        origin +
          '/index/service/serviceOrder/shedSpaceAppointment?' +
          `id=${serviceId}&productId=${orderProduct.productId}&productParentId=${orderProduct.productParentId}&productType=${orderProduct.productType}`,
      )
    },

    // 关联服务单
    openServiceList() {
      this.outerVisible = true
    },
    reFresh(vals) {
      if (vals) {
        this.getData()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .seltAllbtnDis .cell {
  visibility: hidden;
}

::v-deep .el-checkbox__inner {
  border-radius: 100%;
  width: 16px;
  height: 16px;
}

::v-deep .el-dialog__footer {
  border-top: 1px solid #ededed;
}

.managefirmstyle {
  max-height: 370px;
  overflow: auto;
}

.pagination {
  display: flex;
  justify-content: right;
  margin-top: 20px;
}

.mt-20 {
  margin-top: 20px;
}
::v-deep.el-dialog__footer {
  text-align: left;
}
.top {
  ::v-deep.el-descriptions-row {
    display: flex;
    flex-wrap: wrap;
    .el-descriptions-item {
      padding-right: 20px;
    }
  }
}
.order-detail-container {
  .refuse {
    background-color: #fff;
    color: #2861e2;
    border: 1px solid #2861e2;
    &:hover {
      opacity: 0.8;
    }
  }
  .request-refund {
    position: relative;
    margin-left: 12px;
    // padding: 0 6px 0 12px;
    padding: 0 12px;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    // &::before {
    //   content: '';
    //   position: absolute;
    //   top: 8px;
    //   left: 4px;
    //   width: 4px;
    //   height: 4px;
    //   background-color: #fff;
    //   border-radius: 50%;
    // }
  }
  .order-header {
    padding: 20px;
    background-color: #fff;
    .header-title {
      margin-bottom: 16px;
    }
    .header-button-group {
      margin-top: 30px;
      display: flex;
    }
  }
  .header-content {
    margin-top: 16px;
    background-color: #fff;
    .tab-list {
      font-size: 14px;
      color: #9a9d9e;
      border-bottom: 1px solid #ededed;
      .tab-list-item {
        margin-left: 30px;
        padding: 16px 0;
        &:hover {
          cursor: pointer;
          color: #000000;
          border-bottom: 3px solid #2861e2;
        }
      }
      .item-checked {
        color: #000000;
        border-bottom: 3px solid #2861e2;
      }
    }
  }
}
.info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &-item {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-right: 20px;
    &__label {
      color: #999;
      font-size: 14px;
    }
    &__value {
      color: #333;
      font-size: 14px;
      margin-left: 10px;
    }
  }
}
</style>
