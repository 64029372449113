import request from '@/utils/request'

export function getSelectedRelativesList(data) {
  return request({
    url: 'order-web-api/server/service/user/friends/listByQuery',
    method: 'post',
    data,
  })
}

export function deleteById(data) {
  return request({
    url: 'order-web-api/wecom/service/user/friends/deleteById',
    method: 'post',
    data,
  })
}

export function addUserFriends(data) {
  return request({
    url: 'order-web-api/admin/service/user/friends/addUserFriends',
    method: 'post',
    data,
  })
}
export function getServiceById(data) {
  return request({
    url: 'order-web-api/admin/orderservice/getServiceById',
    method: 'post',
    data,
  })
}
