<template>
  <div v-if="status >= 2" class="photographer">
    <div class="header">
      <span class="left">
        拍摄人档案
        <span v-if="friendsNum">({{ friendsNum }})</span>
      </span>

      <span class="right">
        <el-button v-if="confirmFriends != '1'" size="mini" @click="onAddUserFriends"
          >通知客户确认</el-button
        >
        <el-button
          v-else
          type="text"
          size="mini"
          :disabled="ture"
          style="color: #999999; cursor: default"
          >已通知客户确认</el-button
        >
        <el-button type="primary" size="mini" @click="showDrawer = true">选择亲友</el-button>
      </span>
    </div>

    <el-table
      :data="tableData"
      border
      style="margin-top: 20px"
      max-height="300px"
      :header-row-style="{ background: '#F5F7FA' }"
    >
      <el-table-column prop="relationName" label="关系" width="60px" fixed="left"></el-table-column>
      <el-table-column label="昵称" prop="nickname"></el-table-column>
      <el-table-column label="生日" prop="birthday"></el-table-column>
      <el-table-column prop="height" label="身高"></el-table-column>
      <el-table-column prop="weight" label="体重"></el-table-column>
      <el-table-column prop="clothesSize" label="衣码"></el-table-column>
      <el-table-column prop="shoeSize" label="鞋码"></el-table-column>
      <el-table-column prop="profession" label="职业"></el-table-column>
      <el-table-column label="过敏物">
        <template slot-scope="scope">
          <div v-if="scope.row.allergen" type="text" style="color: #ff0000">
            {{ scope.row.allergen }}
          </div>
          <div v-else>--</div>
        </template>
      </el-table-column>
      <el-table-column prop="character" label="性格"></el-table-column>
      <el-table-column prop="hobby" label="兴趣爱好"></el-table-column>
      <el-table-column label="近照" width="60px">
        <template slot-scope="scope">
          <el-button type="text" style="color: #409eff" @click="onViewer(scope.row)">
            {{ hanldlePhotos(scope.row) ? hanldlePhotos(scope.row) : '--' }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100px" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" @click="onEdit(scope.row)">编辑</el-button>
          <el-button type="text" @click="onDel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <SelectRelativesDrawer
      :show-drawer.sync="showDrawer"
      :service-id="serviceId"
      :customer-id="customerId"
      :user-id="userId"
      :biz-line-id="bizLineId"
      @success="reset"
    />
    <AddRelativesDialog
      :show.sync="showDialog"
      :customer-id="customerId"
      :customer-relation-id="customerRelationId"
      :biz-line-id="bizLineId"
      @reset="reset"
    />
    <el-image-viewer v-if="showViewer" :url-list="urlList" :on-close="closeViewer" />
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import {
  getSelectedRelativesList,
  deleteById,
  addUserFriends,
  getServiceById,
} from '@/api/relatives'

export default {
  components: { ElImageViewer },
  props: {
    serviceNo: {
      type: String,
      default: '',
    },
    serviceId: {
      type: String,
      default: '',
    },
    userId: {
      type: String,
      default: '',
    },
    customerId: {
      type: String,
      default: '',
    },
    friendsNum: {
      type: Number,
      default: 0,
    },
    status: {
      type: Number,
      default: 0,
    },
    bizLineId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableData: [],
      showDrawer: false,
      showDialog: false,
      customerRelationId: '',
      urlList: [],
      showViewer: false,
      confirmFriends: '',
    }
  },

  mounted() {
    this.getData()
    this.getIsShow()
  },
  methods: {
    getIsShow() {
      getServiceById({
        data: this.serviceId,
      }).then((res) => {
        console.log(res)
        this.confirmFriends = res.data.confirmFriends
      })
    },
    hanldlePhotos(row) {
      console.log(row)
      if (!row.photos) return 0
      const photos = row.photos.split(',')
      return photos.length
    },
    onViewer(row) {
      if (!row.photos) return
      const photos = row.photos.split(',')
      this.urlList = photos
      this.showViewer = true
    },
    closeViewer() {
      this.showViewer = false
    },
    async getData() {
      const res = await getSelectedRelativesList({
        data: {
          serviceId: this.serviceId,
        },
      })

      this.tableData = res.data
    },
    onEdit(e) {
      this.customerRelationId = e.customerRelationId
      this.showDialog = true
    },
    onDel(e) {
      this.$confirm('确认移除该拍摄人?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deleteById({
            data: e.friendsId,
          }).then((res) => {
            this.reset()
            console.log(res)
          })
        })
        .catch(() => {})
    },
    reset() {
      this.getData()
      this.getIsShow()
      this.$emit('reset')
    },
    onAddUserFriends() {
      addUserFriends({
        data: {
          serviceId: this.serviceId,
          notifyCustomer: true,
        },
      })
        .then(() => {
          this.$message.success('通知客户成功~')
          this.getIsShow()
        })
        .catch(() => {
          this.$message.error('通知客户失败~')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.photographer {
  background: #fff;
  width: 100%;
  height: 100%;
  margin-top: 40px;
  padding: 0 18px 24px 18px;
  box-sizing: border-box;

  .header {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    align-items: center;
    position: relative;
  }

  .header::before {
    content: '';
    height: 18px;
    border-left: 3px solid #363f47;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    position: absolute;
    left: 0px;
    top: 5px;
  }

  .text-button {
    color: #2861e2;
    cursor: pointer;
  }

  ::v-deep .el-dialog__header {
    background: #fff;
    border-bottom: 1px solid #ededed;
    border-radius: 6px 6px 0 0;
  }

  ::v-deep .el-dialog {
    border-radius: 6px;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #ededed !important;
  }

  .questionnaire-dialog {
    ::v-deep .el-dialog__body {
      padding-top: unset;
      padding-bottom: unset;
      overflow: hidden;
    }
  }

  .dialog-content {
    display: flex;

    .left {
      flex: 1;
      border-right: 1px solid #ededed;
      padding-top: 24px;
      padding-right: 20px;

      .questionnaire-list {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        height: 540px;
        overflow-x: hidden;
        overflow-y: auto;

        ::v-deep .el-checkbox {
          width: 100%;
          display: flex;

          .el-checkbox__input {
            line-height: 40px;
            display: flex;
            align-items: center;
          }

          .el-checkbox__label {
            line-height: 40px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-right: 5px;
          }
        }

        .questonnaire-id {
          color: #cccccc;
        }
      }

      .questionnaire-list::-webkit-scrollbar {
        width: 6px;
      }

      .questionnaire-list::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: #e0e3e7;
      }
    }

    .right {
      min-width: 230px;
      padding-left: 20px;
      padding-top: 24px;
      padding-right: 15px;

      .tag-list {
        display: flex;
        flex-direction: column;
        margin-top: 13px;

        .tag-item {
          margin-bottom: 12px;
        }

        ::v-deep .el-tag--small {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        ::v-deep .el-icon-close {
          transform: scale(1);
        }
      }
    }
  }
}
</style>
