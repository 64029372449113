<template>
  <div class="shootPlan">
    <div class="content-item">
      <div class="content-header">
        <span class="header-left">预约备注</span>
      </div>

      <div class="content-body">
        {{ photoPlanForm.shootRemark }}
      </div>
    </div>

    <!-- <div class="content-item">
      <div class="content-header">
        <span class="header-left">出棚总结</span>
      </div>

      <div class="content-body">
        {{ photoPlanForm.shootSummary }}
      </div>
    </div> -->

    <div class="content-item">
      <div class="content-header">
        <span class="header-left">拍摄规划</span>
      </div>

      <div class="content-body">{{ photoPlanForm.shootPlan }}</div>
    </div>
  </div>
</template>

<script>
import { storeGetServiceById } from '@/api/order'

export default {
  name: '',
  data() {
    return {
      photoPlanForm: {},
    }
  },
  async mounted() {
    await this.getDetail()
  },
  methods: {
    async getDetail() {
      const res = await storeGetServiceById({ data: this.$route.query.serviceId })

      if (res.data) {
        const { shootSummary, shootRemark, shootPlan } = res.data

        this.photoPlanForm = {
          shootPlan: shootPlan || '暂无拍摄规划',
          shootRemark: shootRemark || '暂无预约备注',
          shootSummary: shootSummary || '暂无出棚总结',
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.shootPlan {
  background: #fff;
  height: 100%;
  padding: 34px 20px;

  .content-item {
    .content-header {
      padding-left: 11px;
      position: relative;

      .header-left {
        font-size: 16px;
      }

      .header-left::before {
        content: '';
        height: 18px;
        border-left: 3px solid #363f47;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        position: absolute;
        left: 0px;
        top: 0px;
      }
    }

    .content-body {
      margin-top: 27px;
      font-size: 14px;
      color: #111;
    }
  }

  .content-item + .content-item {
    margin-top: 43px;
  }
}
</style>
