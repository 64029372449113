<template>
  <div class="beforeShoot">
    <div class="header">
      <span class="left">新品推荐</span>

      <span class="right">
        <el-button type="primary" size="mini" @click="openAddDialog">添加问卷</el-button>
      </span>
    </div>

    <el-table :data="tableData" border style="margin-top: 20px">
      <el-table-column prop="questionnaireName" label="新品推荐名称"></el-table-column>
      <el-table-column label="发送时间">
        <template slot-scope="scope">
          {{ scope.row.sendTime ? scope.row.sendTime : '/' }}
        </template>
      </el-table-column>
      <el-table-column label="提交时间">
        <template slot-scope="scope">
          {{ scope.row.submitTime ? scope.row.submitTime : '/' }}
        </template>
      </el-table-column>
      <el-table-column prop="serviceNo" label="服务订单"></el-table-column>
      <el-table-column prop="userPhone" label="手机号码"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <span
            v-if="!scope.row.sendTime && !scope.row.submitTime"
            class="text-button"
            @click="advanceSend(scope.row)"
            >提前发送</span
          >
          <span
            v-else-if="scope.row.sendTime && !scope.row.submitTime"
            class="text-button"
            @click="resend(scope.row)"
            >重新发送</span
          >
          <span v-else class="text-button" @click="gotoDetail(scope.row)">查看详情</span>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      class="questionnaire-dialog"
      title="选择问卷"
      :visible.sync="addDialogVisible"
      :before-close="dialogClose"
    >
      <div class="dialog-content">
        <div class="left">
          <el-input v-model="filterText" placeholder="请输入问卷ID/名称"></el-input>

          <el-checkbox-group v-model="selectList" class="questionnaire-list">
            <el-checkbox v-for="(item, index) in showQuestionnaire" :key="index" :label="item">
              <span>{{ item.questionnaireName }}</span>
              <span class="questonnaire-id">ID：{{ item.questionnaireNo }}</span>
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="right">
          <div>已选{{ selectList.length }}</div>

          <div class="tag-list">
            <el-tag
              v-for="(item, index) in selectList"
              :key="index"
              class="tag-item"
              size="small"
              closable
              @close="deleteTag(item)"
              >{{ item.questionnaireNo }} {{ item.questionnaireName }}</el-tag
            >
          </div>
        </div>
      </div>

      <div slot="footer">
        <el-button size="mini" @click="cancelSend">取 消</el-button>
        <el-button size="mini" type="primary" @click="confirmSend">直接发送</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getQuestionnaireLists,
  sendInAdvance,
  resend,
  getAllQuestionnaire,
  sendDirectly,
} from '@/api/questionnaire'

export default {
  props: {
    serviceNo: {
      type: String,
      default: '',
    },
    userPhone: {
      type: String,
      default: '',
    },
    productId: {
      type: String,
      default: '',
    },
    userId: {
      type: String,
      default: '',
    },
    serviceId: {
      type: String,
      default: '',
    },
    customerId: {
      type: String,
      default: '',
    },
    bizLineId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableData: [],
      addDialogVisible: false,
      sendButtonTimer: null,
      allQuestionnaireList: [],
      selectList: [],
      filterText: '',
    }
  },
  computed: {
    showQuestionnaire() {
      if (!this.filterText) return this.allQuestionnaireList

      const newArr = this.allQuestionnaireList.filter((el) => {
        if (
          el.questionnaireName.includes(this.filterText) ||
          el.questionnaireNo.includes(this.filterText)
        ) {
          return true
        }
        return false
      })

      return newArr
    },
  },
  async mounted() {
    await this.getData()
  },
  methods: {
    openAddDialog() {
      this.getAllQuestionnaire()
      this.addDialogVisible = true
    },
    async getData() {
      const reqObj = {
        data: {
          serviceNo: this.serviceNo,
          userPhone: this.userPhone,
          productId: this.productId,
        },
      }

      const res = await getQuestionnaireLists(reqObj)
      console.log(res)
      if (res.success) {
        this.tableData = res.data
      }
    },
    async advanceSend(row) {
      clearTimeout(this.sendButtonTimer)

      this.sendButtonTimer = setTimeout(async () => {
        const res = await sendInAdvance({ data: row.questionnaireUserId })
        if (res.success) {
          this.$message.success('发送成功')
          this.getData()
        }
      }, 500)
    },
    async resend(row) {
      clearTimeout(this.sendButtonTimer)

      this.sendButtonTimer = setTimeout(async () => {
        const res = await resend({ data: row.questionnaireUserId })
        if (res.success) {
          this.$message.success('发送成功')
          this.getData()
        }
      }, 500)
    },
    async sendTemplate() {
      clearTimeout(this.sendButtonTimer)

      this.sendButtonTimer = setTimeout(async () => {
        const reqObj = {
          data: {
            questionnaireIds: this.selectList.map((el) => el.id),
            userPhone: this.userPhone,
            serviceNo: this.serviceNo,
            userId: this.userId,
          },
        }

        const res = await sendDirectly(reqObj)
        if (res.success) {
          this.$message.success('发送成功')
          this.cancelSend()
        }
        await this.getData()
      }, 500)
    },
    async getAllQuestionnaire() {
      const reqObj = {
        data: {
          isSave: 1,
          serviceNo: this.serviceNo,
        },
        pageNumber: 1,
        pageSize: 1000,
      }

      const res = await getAllQuestionnaire(reqObj)

      if (res.success) {
        this.allQuestionnaireList = res.data.content
      }
    },
    deleteTag(tag) {
      const deleteIndex = this.selectList.findIndex((el) => el.id === tag.id)
      this.selectList.splice(deleteIndex, 1)
    },
    confirmSend() {
      this.$confirm('是否确认发送？')
        .then(() => {
          this.sendTemplate()
        })
        .catch()
    },
    cancelSend() {
      this.selectList = []
      this.filterText = ''
      this.addDialogVisible = false
    },
    gotoDetail(row) {
      this.$router.push({
        path: '/questionnaireDetail',
        query: {
          questionnaireId: `${row.questionnaireId}`,
          questionnaireUserId: `${row.questionnaireUserId}`,
          productId: `${this.productId}`,
          serviceId: this.serviceId,
          customerId: this.customerId,
          bizLineId: this.bizLineId,
        },
      })
    },
    dialogClose(done) {
      this.selectList = []
      this.filterText = ''
      done()
    },
  },
}
</script>

<style lang="scss" scoped>
.beforeShoot {
  background: #fff;
  height: 100%;
  padding: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    align-items: center;
    position: relative;
  }

  .header::before {
    content: '';
    height: 18px;
    border-left: 3px solid #363f47;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    position: absolute;
    left: 0px;
    top: 5px;
  }

  .text-button {
    color: #2861e2;
    cursor: pointer;
  }

  ::v-deep .el-dialog__header {
    background: #fff;
    border-bottom: 1px solid #ededed;
    border-radius: 6px 6px 0 0;
  }

  ::v-deep .el-dialog {
    border-radius: 6px;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #ededed !important;
  }

  .questionnaire-dialog {
    ::v-deep .el-dialog__body {
      padding-top: unset;
      padding-bottom: unset;
      overflow: hidden;
    }
  }

  .dialog-content {
    display: flex;

    .left {
      flex: 1;
      border-right: 1px solid #ededed;
      padding-top: 24px;
      padding-right: 20px;

      .questionnaire-list {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        height: 540px;
        overflow-x: hidden;
        overflow-y: auto;

        ::v-deep .el-checkbox {
          width: 100%;
          display: flex;

          .el-checkbox__input {
            line-height: 40px;
            display: flex;
            align-items: center;
          }

          .el-checkbox__label {
            line-height: 40px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-right: 5px;
          }
        }

        .questonnaire-id {
          color: #cccccc;
        }
      }

      .questionnaire-list::-webkit-scrollbar {
        width: 6px;
      }

      .questionnaire-list::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: #e0e3e7;
      }
    }

    .right {
      min-width: 230px;
      padding-left: 20px;
      padding-top: 24px;
      padding-right: 15px;

      .tag-list {
        display: flex;
        flex-direction: column;
        margin-top: 13px;

        .tag-item {
          margin-bottom: 12px;
        }

        ::v-deep .el-tag--small {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        ::v-deep .el-icon-close {
          transform: scale(1);
        }
      }
    }
  }
}
</style>
