<template>
  <div>
    <el-dialog :visible.sync="mapVisible" width="800px" :before-close="handleClose">
      <div id="container" ref="map" v-loading="loading" :style="{ width: '100%', height: '600px' }"></div>
    </el-dialog>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
window._AMapSecurityConfig = {
  securityJsCode: 'ddee71306ba9e8aa40541a0cc27b67ff',
}
export default {
  props: {
    mapVisible: {
      type: Boolean,
      default: false,
    },
    lng: {
      type: [Number, String],
      default: '',
    },
    lat: {
      type: [Number, String],
      default: '',
    },
    adress: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      map: null,
      loading: false,
    }
  },
  watch: {
    mapVisible: function (val) {
      if (val) {
        this.getMap()
      }
    },
  },
  mounted() {},
  methods: {
    handleClose() {
      this.$emit('close')
    },
    getMap() {
      this.loading = true
      AMapLoader.load({
        key: 'acc14952f676bd45c2d27615330de670', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          console.log(this.lng)
          this.map = new AMap.Map('container', {
            // 设置地图容器id
            viewMode: '3D', // 是否为3D地图模式
            zoom: 12, // 初始化地图级别
            center: [this.lng ? this.lng : '116.396741', this.lat ? this.lat : '39.90844'], // 初始化地图中心点位置
          })
          // 创建一个 Marker 实例：
          if (this.lng && this.lat) {
            const marker = new AMap.Marker({
              position: new AMap.LngLat(this.lng, this.lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
              title: this.adress,
            })
            // 将创建的点标记添加到已有的地图实例：
            this.map.add(marker)
          }
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
          console.error(e) // 加载错误提示
        })
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep.el-dialog__header {
  border: none;
}
</style>
